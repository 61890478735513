export default class Address {
  constructor(
    id,
    name,
    isActive,
    streetNumber,
    streetName,
    suburb,
    city,
    province,
    country,
    postalCode,
    lat,
    lng,
    dateCreated,
    isOrganization
  ) {
    this.id = id;
    this.name = name;
    this.isActive = isActive;
    this.streetNumber = streetNumber;
    this.streetName = streetName;
    this.suburb = suburb;
    this.city = city;
    this.province = province;
    this.country = country;
    this.postalCode = postalCode;
    this.lat = lat;
    this.lng = lng;
    this.dateCreated = dateCreated;
    this.isOrganization = isOrganization;
  }
}
