<template>
  <v-app id="smc-app">
    <v-app-bar
      id="smc-app-header"
      class="smc-app-bar"
      height="80"
      absolute
    >
      <div
        class="logo-wrapper"
        :class="$vuetify.breakpoint.smAndUp ? 'wide' : ''"
      >
        <a href="/">
          <v-img
            v-if="$vuetify.breakpoint.smAndUp"
            src="./assets/images/logo.jpg"
            lazy-src="./assets/images/logo.jpg"
            eager
            height="100"
            width="280"
            max-width="280"
            class="ml-3 mt-7"
          />
          <v-img
            v-else
            src="./assets/images/smc-logo.svg"
            lazy-src="./assets/images/smc-logo.svg"
            eager
            height="100"
            width="200"
            max-width="280"
            class="mt-7 ml-n6"
          />
        </a>
      </div>
      <div class="app-bar-wrapper">
        <app-bar-navigation v-if="!hideNavigation" />
      </div>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main
      class="background-image"
      :style="`background-image: url('${backgroundImage}')`"
    >
      <!-- Provides the application the proper gutter -->
      <v-container
        class="home-container"
        :class="$vuetify.breakpoint.xsOnly ? 'px-0' : ''"
      >
        <!-- If using vue-router -->
        <router-view></router-view>

        <contact-speed-dial
          v-if="!isAuthenticated || !hasInnovationPolicy"
        ></contact-speed-dial>

        <!--<cookies-consent></cookies-consent>-->

        <!--<v-snackbar bottom :value="updateExists" :timeout="-1" color="primary">
          An update is available
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="refreshApp">Refresh</v-btn>
          </template>
        </v-snackbar>-->
      </v-container>
    </v-main>
    <site-footer />
  </v-app>
</template>

<script>
import _ from "lodash";
import AppBarNavigation from "./components/app-bar-navigation";
import SiteFooter from "./components/site-footer";
import ContactSpeedDial from "./components/widgets/contact-speed-dial";
//import CookiesConsent from "./components/widgets/cookies-consent";
//import update from "@/mixins/update";

export default {
  name: "app",
  components: {
    AppBarNavigation,
    SiteFooter,
    ContactSpeedDial,
    //CookiesConsent,
    //...components
  },
  //mixins: [update],
  computed: {
    currentPage() {
      return this.$route.path;
    },
    backgroundImage() {
      if (_.startsWith(this.currentPage, "/about")) {
        return require("@/assets/images/background-images/road-transparent.png");
      } else if (_.startsWith(this.currentPage, "/contact")) {
        return require("@/assets/images/background-images/tire-transparent.png");
      } else if (_.startsWith(this.currentPage, "/account")) {
        return require("@/assets/images/background-images/gear-transparent.png");
      } else if (
        _.startsWith(this.currentPage, "/account/signup") ||
        _.startsWith(this.currentPage, "/account/invite") ||
        _.startsWith(this.currentPage, "/invite")
      ) {
        return require("@/assets/images/background-images/car-handle-transparent.png");
      }

      return "";
    },
    hideNavigation() {
      return process.env.VUE_APP_UNDER_MAINTENANCE == "true";
    },
    isAuthenticated() {
      return this.$store.getters["user/isAuthenticated"];
    },
    hasInnovationPolicy() {
      return this.$store.getters["user/hasInnovationPolicy"];
    },
  },
  watch: {
    contactSpeedDial(val) {
      this.contactSpeedDialTooltips = false;
      this.contactSpeedDialTooltipsDisabled = false;
      // delay the show of the tooltip, because showing it imedaitely means the FAB button animation is still running,
      // which causes the positioning of the tooltip label to be wrong.
      val &&
        setTimeout(() => {
          this.contactSpeedDialTooltips = true;
          this.$nextTick(() => (this.contactSpeedDialTooltips = true));
        }, 250);
    },
    isAuthenticated(val) {
      if (val) {
        if (this.hasInnovationPolicy) {
          this.addZohoScript();
        }
      } else {
        this.removeZohoScript();
      }
    },
  },
  mounted() {
    var pwaDismissCookie = this.$cookies.get("pwa_install_dismiss");
    window.addEventListener("beforeinstallprompt", (e) => {
      if (!pwaDismissCookie) {
        this.$store.dispatch("pwaInstall/storeInstallEvent", e);
      }
    });

    window.addEventListener("appinstalled", () => {
      this.$store.dispatch("pwaInstall/clearInstallEvent");
    });

    if (this.isAuthenticated && this.hasInnovationPolicy) {
      this.addZohoScript();
    }
  },
  methods: {
    addZohoScript() {
      let policyList = this.$store.getters["user/getInnovationPolicyList"];

      let scriptText = `var $zoho = $zoho || {}; \n`;
      scriptText += `$zoho.salesiq = $zoho.salesiq || { \n`;
      scriptText += `widgetcode: "f78207afedc819e242f95bf146ec1dc1d410f14967bc61e488f911d8bfcbbad1beed153fda1c71f62ce57fc19ed350d1", values: {}, \n`;
      scriptText += `ready: function () { \n`;
      scriptText += `$zoho.salesiq.visitor.name('${this.$store.getters['user/getEmail']}'); \n`;
      scriptText += `$zoho.salesiq.visitor.info({`;
      for (let i = 0; i < policyList.length; i++)
      {
        scriptText += `'Policy${i}':'${policyList[i]}'`;
        if (i+1 != policyList.length)
        {
          scriptText += ',';
        }
      }
      scriptText += `}); \n`
      //policyList.forEach((policy, index) => {
      //  scriptText += `$zoho.salesiq.visitor.name${index + 1}('${policy}'); \n`;
      //});
      scriptText += `}}; \n`;
      scriptText += `var d = document; \n`;
      scriptText += `s = d.createElement("script"); \n`;
      scriptText += `s.type = "text/javascript"; \n`;
      scriptText += `s.id = "zsiqscript"; \n`;
      scriptText += `s.defer = true; \n`;
      scriptText += `s.src = "https://salesiq.zoho.com/widget"; \n`;
      scriptText += `t = d.getElementsByTagName("script")[0]; \n`;
      scriptText += `t.parentNode.insertBefore(s, t); \n`;

      let script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      script.innerHTML = scriptText;
      script.setAttribute("id", "zsiqchat");
      document.head.append(script);
    },
    removeZohoScript() {
      let script = document.getElementById("zsiqchat");

      if (script) {
        document.head.removeChild(script);
      }
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/app.scss";
@import "./assets/scss/vuetify-elements/app-bar.scss";

.v-sheet.alert-container {
  background: none;
  position: fixed;
  bottom: 70px;
  z-index: 4;
  padding: 0;
}
</style>
