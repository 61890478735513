export const pwaInstall = {
    namespaced: true,
    state: {
        installEvent: undefined
    },
    actions: {
        storeInstallEvent({ commit }, event) {
            commit("storeInstallEvent", event);
        },
        clearInstallEvent({ commit }) {
            commit("clearInstallEvent");
        }
    },
    mutations: {
        storeInstallEvent(state, event) {
            state.installEvent = event;
        },
        clearInstallEvent(state) {
            state.installEvent = undefined;
        }
    },
    getters: {
        getInstallEvent: state => {
            return state.installEvent;
        }
    }
};