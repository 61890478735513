export default class QuoteItem {
    constructor(
        id,
        description,
        note,
        priceExcl,
        priceIncl,
        previousPriceIncl,
        quantity,
        previousQuantity,
        totalIncl,
        verifiedTotalIncl,
        quoteRequestItemId,
        quoteRequestItemName,
        quoteRequestItemQuery,
        quoteRequestItemQueryAnswer,
        amendmentType,
        amendmentStatus
    ) {
        this.id = id;
        this.description = description;
        this.note = note;
        this.priceExcl = priceExcl;
        this.priceIncl = priceIncl;
        this.previousPriceIncl = previousPriceIncl;
        this.quantity = quantity;
        this.previousQuantity = previousQuantity;
        this.totalIncl = totalIncl;
        this.verifiedTotalIncl = verifiedTotalIncl;
        this.quoteRequestItemId = quoteRequestItemId;
        this.quoteRequestItemName = quoteRequestItemName;
        this.quoteRequestItemQuery = quoteRequestItemQuery;
        this.quoteRequestItemQueryAnswer = quoteRequestItemQueryAnswer;
        this.amendmentType = amendmentType;
        this.amendmentStatus = amendmentStatus;
    }
}