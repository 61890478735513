<template>
  <v-parallax :src="src" class="smc-parallax">
    <div
      class="smc-transparent-back"
      :style="`background-color: ${backgroundColor}; opacity: ${backgroundOpacity};`"
    ></div>
  </v-parallax>
  <!--<div
    class="smc-parallax smc-static"
    :style="`background-image: url(${src})`"
  >
    <div
      class="smc-transparent-back"
      :style="`background-color: ${backgroundColor}; opacity: ${backgroundOpacity};`"
    ></div>
  </div>-->
</template>

<script>
export default {
  name: "background-parallax",
  props: {
    srcProp: String,
    blueprintColorProp: String,
    backgroundColorProp: String,
    backgroundOpacityProp: String,
  },
  computed: {
    src() {
      if (this.srcProp) {
        return this.srcProp;
      }
      return this.blueprintColor;
    },
    blueprintColor() {
      if (!this.blueprintColorProp) {
        return require("@/assets/images/background-images/blueprint-grey.jpg");
      }
      return require(`@/assets/images/background-images/blueprint-${this.blueprintColorProp}.jpg`);
    },
    backgroundColor() {
      if (!this.backgroundColorProp) {
        return "white";
      }
      return this.backgroundColorProp;
    },
    backgroundOpacity() {
      if (!this.backgroundOpacityProp) {
        return "0.6";
      }
      return this.backgroundOpacityProp;
    },
  },
};
</script>

<style scoped>
.smc-transparent-back {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.smc-parallax {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  -webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.smc-static {
  height: 500px;
  background-size: cover;
}
</style>