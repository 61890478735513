export default class QuoteRequest {
  constructor(
    id,
    uniqueId,
    userId,
    vehicleMileage,
    existingVehicleMileage,
    vehicleVinNumber,
    vehicleEngineNumber,
    vehicleLicensePlate,
    vehicleName,
    dateCreated,
    dateCreatedUtc,
    datesRequested,
    quoteRequestStatus,
    quoteRequestStatusUsername,
    notes,
    reference,
    licenseDiskPhoto,
    licenseDiskPhotoUrl,
    applicationUser,
    vehicle,
    address,
    travelDistance,
    regionIds,
    partsPreference,
    items,
    quotes,
    isExpired,
    serviceReminder,
    isPolicyQuote,
    quoteType,
    hidePrice,
    isForBusiness,
    attachments,
    quoteCustomerReferences,
    quoteRequestWorkshopMappings,
    quoteRequestOrganizationBranchShare
  ) {
    this.id = id;
    this.uniqueId = uniqueId;
    this.userId = userId;
    this.vehicleMileage = vehicleMileage;
    this.existingVehicleMileage = existingVehicleMileage;
    this.vehicleVinNumber = vehicleVinNumber;
    this.vehicleEngineNumber = vehicleEngineNumber;
    this.vehicleLicensePlate = vehicleLicensePlate;
    this.vehicleName = vehicleName;
    this.dateCreated = dateCreated;
    this.dateCreatedUtc = dateCreatedUtc;
    this.datesRequested = datesRequested;
    this.quoteRequestStatus = quoteRequestStatus;
    this.quoteRequestStatusUsername = quoteRequestStatusUsername;
    this.notes = notes;
    this.reference = reference;
    this.licenseDiskPhoto = licenseDiskPhoto;
    this.licenseDiskPhotoUrl = licenseDiskPhotoUrl;
    this.applicationUser = applicationUser;
    this.vehicle = vehicle;
    this.address = address;
    this.travelDistance = travelDistance;
    this.regionIds = regionIds;
    this.partsPreference = partsPreference;
    this.items = items;
    this.quotes = quotes;
    this.isExpired = isExpired;
    this.serviceReminder = serviceReminder;
    this.isPolicyQuote = isPolicyQuote;
    this.quoteType = quoteType;
    this.hidePrice = hidePrice;
    this.isForBusiness = isForBusiness;
    this.attachments = attachments;
    this.quoteCustomerReferences = quoteCustomerReferences;
    this.quoteRequestWorkshopMappings = quoteRequestWorkshopMappings;
    this.quoteRequestOrganizationBranchShare = quoteRequestOrganizationBranchShare;
  }
}
