import axios from "axios";
import api from "../services/api";
import { authService } from "../services";
import store from "../store";
import router from "../router";

export default function setup() {
    // Request
    api.interceptors.request.use(
        config => {
            if (store.getters["user/isAuthenticated"]) {
                const accessToken = store.getters["user/getAccessToken"];
                if (accessToken) {
                    config.headers.common["Authorization"] = `bearer ${accessToken.token}`;
                }

                const email = store.getters["user/getEmail"]
                if (email) {
                    config.headers["c-username"] = email;
                }
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    // Response
    api.interceptors.response.use(
        response => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        },
        async error => {
            if (error.response.status) {
                switch (error.response.status) {
                    case 401: {

                        let originalRequest = error.config;
                        originalRequest._retry = true;

                        const refreshToken = store.getters["user/getRefreshToken"];
                        const accessToken = store.getters["user/getAccessToken"];

                        // todo - try refreshing token first.
                        // consider: https://github.com/axios/axios/issues/1290
                        if (accessToken && refreshToken) {
                            var result = await authService.refreshToken({
                                accessToken: accessToken.token,
                                refreshToken: refreshToken.token
                            });

                            if (result && result.status == 200) {
                                store.dispatch("user/setToken", {
                                    accessToken: {
                                        token: result.data.accessToken,
                                        tokenExpiresUtc: result.data.accessTokenExpiresUtc,
                                        tokenExpires: result.data.accessTokenExpires
                                    },
                                    refreshToken: {
                                        token: result.data.refreshToken,
                                        tokenExpiresUtc: result.data.refreshTokenExpiresUtc,
                                        tokenExpires: result.data.refreshTokenExpires
                                    }
                                });

                                let headerAuth = 'Bearer ' + result.data.accessToken;
                                originalRequest.headers['Authorization'] = headerAuth;

                                return axios(originalRequest);
                            }
                            else {
                                store.dispatch("user/removeUser");

                                router.replace({
                                    path: "/account/login"
                                });
                            }
                        }

                        break;
                    }
                }
            }
            return Promise.reject(error.response);
        }
    );
}