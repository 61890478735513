import api from "./api";

const pay = async quoteRequestId => {
    try {
      var result = await api.post(`/api/payment/Pay/${quoteRequestId}`);

      window.location.assign(result.data.redirectUrl);

      return result;
    } catch (error) {
        throw error;
    }
  };

export const paymentService = { pay };