<template>
  <div class="my-auto">
    <v-dropdown :shown.sync="isOpen" :distance="6" container="#smc-app" placement="bottom-end">
      <!-- This will be the popover reference (for the events and position) -->
      <v-btn class="my-auto mx-2 text-none d-none d-md-flex" color="primary" rounded>
        <span class="mr-1"> Events </span>
        <v-icon small dark> mdi-bell-badge </v-icon>
      </v-btn>
      <v-btn class="my-auto text-none d-flex d-md-none events-btn" small fab color="primary" rounded>
        <v-icon small dark> mdi-bell-badge </v-icon>
      </v-btn>

      <!-- This will be the content of the popover -->
      <template #popper>
        <v-card :loading="loading" class="recent-actions-card smc-card-dialog">
          <v-card-title class="smc-card-dialog-title-inset primary white--text">
            <span class="mx-auto"> Recent Events </span>
          </v-card-title>
          <v-card-text class="px-0">
            <v-card rounded elevation="2" outlined class="ma-4" v-for="(item, index) in actions" v-bind:key="index"
              @click="openAction(item)">
              <v-card-title>
                <v-chip-group column>
                  <v-chip v-if="item.actionDescription" :color="recentActionSourceParams(item.actionSource).color" :class="`${recentActionSourceParams(item.actionSource).text
                    }--text`">
                    {{ item.actionDescription }}
                  </v-chip>
                  <v-chip v-if="item.vehicleName" outlined color="primary">
                    {{ item.vehicleName }}
                  </v-chip>
                  <v-chip outlined color="secondary">
                    {{ dateHelper.formatDateLongHours(item.actionDate) }}
                  </v-chip>
                </v-chip-group>
              </v-card-title>
              <v-card-text class="text-left">
                <v-row>
                  <v-col>
                    <p>
                      {{ item.vehicleSummary }}
                    </p>
                    <h3>
                      <strong>{{ item.workshopName }}</strong>
                    </h3>
                  </v-col>
                  <v-col class="shrink pr-1">
                    <img class="smc-search-image mr-0" :src="item.vehicleImageUrl" style="
                        width: 180px;
                        margin-right: 15px;
                        border-radius: 15px;
                      " />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="text-right">
                <v-row>
                  <v-col>
                    <v-chip :color="recentActionSourceParams(item.actionSource).color" :class="`${recentActionSourceParams(item.actionSource).text
                      }--text`">
                      {{ recentActionSourceParams(item.actionSource).label }}
                    </v-chip>
                    <v-chip class="ml-2" color="black" outlined>
                      {{ recentActionTypes(item.actionType) }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
            <scroll-pagination ref="recentActionsScrollPagination" :loader-method-prop="loadMoreActions"
              :loader-disable-prop="scrollDisabled"></scroll-pagination>
          </v-card-text>
        </v-card>
      </template>
    </v-dropdown>
  </div>
</template>

<script>
import { userService } from "@/services";
import { dateHelper, recentActionSources, recentActionTypes } from "@/helpers";

import scrollPagination from "@/components/scroll-pagination";

export default {
  name: "recent-actions",
  components: {
    scrollPagination,
  },
  data: () => ({
    dateHelper,

    isOpen: false,
    loading: false,
    scrollDisabled: false,

    page: 1,
    actions: [],
  }),
  watch: {
    isOpen(val) {
      if (val) {
        this.actions = [];
        this.page = 1;
        this.scrollDisabled = false;
      }
    },
  },
  methods: {
    async loadMoreActions() {
      if (!this.loading) {
        this.loading = true;
        var result = await userService.queryUserRecentActions(this.page, "");
        if (result.data && result.data.length > 0) {
          this.actions.push(...result.data);
        } else {
          this.scrollDisabled = true;
        }
        this.loading = false;
        this.page++;
      }
    },
    openAction(item) {
      if (item.actionSource == 0) {
        let path = "/new-quote";
        if (this.$route.name === path) {
          path = `${path}?t=123`;
        }

        this.$router.push({
          name: "new-quote",
          params: { vehicleMappingIdProp: item.navigationId },
        });
      } else {
        this.$router.push(`/quotes/${item.navigationId}`);
      }
      this.isOpen = false;
    },
    recentActionSourceParams(index) {
      return recentActionSources[index];
    },
    recentActionTypes(index) {
      return recentActionTypes[index];
    },
  },
};
</script>

<style lang="scss">
.v-popper--theme-dropdown .v-popper__inner {
  border-radius: 30px !important;
  border-top-right-radius: 15px;
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
.recent-actions-card {
  max-width: 550px;
  max-height: 90vh;
  margin-right: 0;
  overflow-y: scroll;

  @media screen and (max-width: 550px) {
    max-width: 100vw;
    margin-right: 0;
    max-height: 100%;
  }
}

.smc-card-dialog.v-card>.v-card__title.smc-card-dialog-title-inset {
  width: fit-content;
  min-width: 250px;
  padding: 10px 20px;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 32px !important;

  @media screen and (max-width: 600px) {
    width: 100%;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 30px !important;
  }
}

.smc-card-dialog.v-card>.v-card__subtitle.smc-card-dialog-subtitle-inset {
  @media screen and (max-width: 600px) {
    width: 100%;
    position: relative;
    border-bottom-right-radius: 32px;
  }
}
</style>