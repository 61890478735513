<template>
  <v-sheet white>
    <v-row>
      <v-col>
        <v-skeleton-loader class="pb-3" type="heading"></v-skeleton-loader>
        <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        <v-skeleton-loader type="paragraph"></v-skeleton-loader>
      </v-col>
      <v-col class="right-align">
        <v-skeleton-loader class="mr-5 inline-element" type="image" style="height: 125px; min-width: 125px; width: 250px;"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "vehicle-search-details-skeleton-loader"
};
</script>

<style lang="scss" scoped>

.right-align{
  text-align:right;
}

.inline-element{
  display:inline-block;
}

</style>