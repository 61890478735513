<template>
  <div>
    <v-btn
      href="https://wa.me/27652282401"
      target="_blank"
      fab
      fixed
      right
      bottom
      :small="$vuetify.breakpoint.smOnly"
      :x-small="$vuetify.breakpoint.xsOnly"
      class="whatsapp-btn"
    >
      <v-img
        :src="require('@/assets/images/whatsapp.png')"
        :height="
          $vuetify.breakpoint.xsOnly
            ? '35'
            : $vuetify.breakpoint.smOnly
            ? '45'
            : '60'
        "
        :width="
          $vuetify.breakpoint.xsOnly
            ? '35'
            : $vuetify.breakpoint.smOnly
            ? '45'
            : '60'
        "
        contain
      >
      </v-img>
    </v-btn>
    <v-btn
      @click="toggleShow()"
      color="primary"
      fab
      fixed
      right
      bottom
      :small="$vuetify.breakpoint.smOnly"
      :x-small="$vuetify.breakpoint.xsOnly"
    >
      <v-icon v-if="show">mdi-phone</v-icon>
      <v-icon v-else>mdi-phone-hangup</v-icon>
    </v-btn>
    <v-slide-x-reverse-transition>
      <v-card
        class="smc-contact-card text-left"
        width="320"
        elevation="6"
        v-if="show"
      >
        <v-toolbar color="primary">
          <v-toolbar-title class="white--text">Contact us</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="white--text" icon @click="setShow(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-subtitle class="subtitle-1">
          If you need assistance, please do not hesitate to call or email us!
        </v-card-subtitle>
        <v-card-text>
          <v-list nav class="pa-0">
            <v-list-item class="ma-0" :href="`tel:${contactPhone}`">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-phone-forward</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ contactPhone }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="ma-0" :href="`mailto:${contactEmail}`">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ contactEmail }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
export default {
  name: "ContactSpeedDial",
  data: function () {
    return {
      contactPhone: process.env.VUE_APP_CONTACT_NUMBER,
      contactEmail: process.env.VUE_APP_CONTACT_EMAIL,
    };
  },
  computed: {
    show() {
      return this.$store.getters["contactSpeedDial/getShow"];
    },
  },
  methods: {
    toggleShow() {
      this.$store.dispatch("contactSpeedDial/toggle");
    },
    setShow(val) {
      this.$store.dispatch("contactSpeedDial/setShow", val);
    },
  },
};
</script>

<style scoped>
.smc-contact-card {
  position: fixed;
  right: 25px;
  bottom: 85px;
  z-index: 4;
}

.whatsapp-btn {
  margin-right: 70px;
  z-index: 5;
}

@media screen and (min-width: 601px) and (max-width: 960px) {
  .whatsapp-btn {
    margin-right: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .whatsapp-btn {
    margin-right: 0;
    margin-bottom: 45px;
  }
}
</style>