var moment = require("moment");

const formatDateShort = date => {
  return moment(date).format("DD/MM/YYYY");
};

const formatDateHours = date => {
  return moment(date).format("DD/MM/YYYY HH:mm");
};

const formatDateLong = date => {
  return moment(date).format("dddd, Do MMMM YYYY");
};

const formatDateLongHours = date => {
  return moment(date).format("ddd HH:mm, Do MMM YYYY");
};

const formatDateYear = date => {
  return moment(date).format("YYYY");
};

const formatDateYearMonth = date => {
  return moment(date).format("MMMM, YYYY");
};

const formatDateCustom = (dateValue, dateFormat) => {
  return moment(dateValue).format(dateFormat);
};

const formatTimeSpan = timespan => {
  const duration = moment.duration(timespan);
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  let formattedTime = '';

  if (years > 0) {
    formattedTime += `${years} year${years !== 1 ? 's' : ''} `;
  }
  
  if (months > 0) {
    formattedTime += `${months} month${months !== 1 ? 's' : ''} `;
  }

  if (days > 0) {
    formattedTime += `${days} day${days !== 1 ? 's' : ''} `;

    // If timespan crosses over 1 or more months, exclude hours and minutes
    if (months > 0) {
      return formattedTime.trim();
    }
  }

  formattedTime += `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''}`;

  return formattedTime.trim();
};

const datesEqual = (date1, date2, granularity) => {
  if (granularity) {
    return moment(date1).isSame(date2, granularity);
  } else {
    return moment(date1).isSame(date2);
  }
};

const dateDifference = (dateFrom, dateTo, unitOfTime) => {
  var dateFromObj = moment(dateFrom);
  var dateToObj = moment(dateTo);
  return dateFromObj.diff(dateToObj, unitOfTime);
};

const isBefore = (dateFrom, dateTo) => {
  var dateFromObj = moment(dateFrom);
  var dateToObj = moment(dateTo);
  return dateFromObj.isBefore(dateToObj);
};

const isAfter = (dateFrom, dateTo) => {
  var dateFromObj = moment(dateFrom);
  var dateToObj = moment(dateTo);
  return dateFromObj.isAfter(dateToObj);
};

const convertToDate = date => {
  return moment(date).toDate();
};

const getFirstDayOfMonth = date => {
  return moment(date).startOf("month").toDate();
};

const getLastDayOfMonth = date => {
  return moment(date).endOf("month").toDate();
};

const dateDifferenceFromToday = (date, unitOfTime) => {
  return dateDifference(date, moment(), unitOfTime);
};

const getDaysBetweenDates = (dateFrom, dateTo) => {
  var dateArray = new Array();
  var currentDate, endDate;

  if (dateFrom instanceof Date) {
    currentDate = dateFrom;
  }
  else {
    currentDate = new Date(dateFrom);
  }
  if (dateTo instanceof Date) {
    endDate = dateTo;
  }
  else {
    endDate = new Date(dateTo);
  }

  let counter = 0;
  // add max counter in case of infiniate while loop
  while (currentDate <= endDate && counter < 1000) {
    counter++;
    dateArray.push(new Date(currentDate))
    currentDate = currentDate.addDays(1);
  }

  return dateArray;
};

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

export const dateHelper = {
  formatDateShort,
  formatDateHours,
  formatDateLong,
  formatDateLongHours,
  formatDateYear,
  formatDateYearMonth,
  formatDateCustom,
  formatTimeSpan,
  datesEqual,
  dateDifference,
  isBefore,
  isAfter,
  convertToDate,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  dateDifferenceFromToday,
  getDaysBetweenDates
};
