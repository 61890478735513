const vatPercent = parseInt(process.env.VUE_APP_VAT_PERCENT);

const addVat = (exlusiveAmount) => {
    var parsedExlusiveAmount = parseFloat(exlusiveAmount);
    var inclusiveAmount = (parsedExlusiveAmount + ((parsedExlusiveAmount * vatPercent) / 100));
    return parseFloat(inclusiveAmount.toFixed(2));
}

const removeVat = (inclusiveAmount) => {
    var parsedInclusiveAmount = parseFloat(inclusiveAmount);
    var exlusiveAmount = (parsedInclusiveAmount * 100) / (100 + vatPercent);
    return parseFloat(exlusiveAmount.toFixed(2));
}

const determineVat = (inclusiveAmount) => {
    var parsedInclusiveAmount = parseFloat(inclusiveAmount);
    var exlusiveAmount = (parsedInclusiveAmount * 100) / (100 + vatPercent);
    return parseFloat((parsedInclusiveAmount - exlusiveAmount).toFixed(2));
}

const formatNumberAsCurrency = (number) => {
    return number.toLocaleString('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
    });
}

export const financeHelper = {
    vatPercent,
    addVat,
    removeVat,
    determineVat,
    formatNumberAsCurrency
};