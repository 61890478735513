import axios from "axios";
import Vehicle from "@/models/vehicle";
import { vehicleHelper } from "../helpers";
import api from "./api";
import fileDownload from "js-file-download";

const CancelToken = axios.CancelToken;
let cancel;

const searchVehicles = async searchTerm => {
  var vehicles = [];

  if (cancel) {
    // if there is a cancel token, call it and cancel all previous requests
    cancel();
  }

  try {
    var result = await api.post(`/api/vehicles`, { searchTerm }, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    });

    for (let vehicle of result.data) {
      vehicles.push(
        new Vehicle(
          vehicle.id,
          vehicle.brand,
          vehicle.brandCode,
          vehicle.range,
          vehicle.rangeCode,
          vehicle.model,
          vehicle.modelCode,
          vehicle.year,
          vehicle.introductionDate,
          vehicle.discontinuedDate,
          vehicle.galPic,
          vehicle.imageUrl != ""
            ? vehicle.imageUrl
            : require("@/assets/images/no-entry-for-motor-vehicle.png"),
          vehicle.diskDriveReference,
          vehicle.classType,
          vehicle.description,
          vehicle.capacity,
          vehicle.transmissionType,
          vehicle.fuelType,
          vehicle.driveType,
          vehicle.vehicleSummary
        )
      );
    }

    return result.data;
  } catch (error) {
    throw error;
  }
};

const vehicleLookup = async reference => {
  let vehicle = new vehicleHelper.getEmptyVehicle();

  try {
    var result = await api.get(`/api/vehicles/${reference}/`);

    vehicle = new Vehicle(
      0,
      result.data.brand,
      result.data.brandCode,
      result.data.range,
      result.data.rangeCode,
      result.data.model,
      result.data.modelCode,
      result.data.year,
      result.data.introductionDate,
      result.data.discontinuedDate,
      result.data.galPic,
      result.data.imageUrl != ""
        ? result.data.imageUrl
        : require("@/assets/images/no-entry-for-motor-vehicle.png"),
      result.data.diskDriveReference,
      result.data.classType,
      result.data.description,
      result.data.capacity,
      result.data.transmissionType,
      result.data.fuelType,
      result.data.driveType,
      result.data.vehicleSummary
    );

    return result.data;
  } catch (error) {
    throw error;
  }
};

const getBrands = async () => {
  var vehicles = [];

  try {
    var result = await api.get(`/api/vehicles/getbrands`);

    for (let vehicle of result.data) {
      vehicles.push(
        new Vehicle(
          vehicle.id,
          vehicle.brand,
          vehicle.brandCode,
          vehicle.range,
          vehicle.rangeCode,
          vehicle.model,
          vehicle.modelCode,
          vehicle.year,
          vehicle.introductionDate,
          vehicle.discontinuedDate,
          vehicle.galPic,
          vehicle.imageUrl != ""
            ? vehicle.imageUrl
            : require("@/assets/images/no-entry-for-motor-vehicle.png"),
          vehicle.diskDriveReference,
          vehicle.classType,
          vehicle.description,
          vehicle.capacity,
          vehicle.transmissionType,
          vehicle.fuelType,
          vehicle.driveType,
          vehicle.vehicleSummary
        )
      );
    }

    return vehicles;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getYears = async brandName => {
  var years = [];

  try {
    var result = await api.get(`/api/vehicles/getyears?brandName=${brandName}`);

    for (let vehicle of result.data) {
      years.push(
        new Vehicle(
          vehicle.id,
          vehicle.brand,
          vehicle.brandCode,
          vehicle.range,
          vehicle.rangeCode,
          vehicle.model,
          vehicle.modelCode,
          vehicle.year,
          vehicle.introductionDate,
          vehicle.discontinuedDate,
          vehicle.galPic,
          vehicle.imageUrl != ""
            ? vehicle.imageUrl
            : require("@/assets/images/no-entry-for-motor-vehicle.png"),
          vehicle.diskDriveReference,
          vehicle.classType,
          vehicle.description,
          vehicle.capacity,
          vehicle.transmissionType,
          vehicle.fuelType,
          vehicle.driveType,
          vehicle.vehicleSummary
        )
      );
    }

    return years;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getRanges = async (brandName, year) => {
  var vehicles = [];

  try {
    var result = await api.get(
      `/api/vehicles/getranges?brandName=${brandName}&year=${year}`
    );

    for (let vehicle of result.data) {
      vehicles.push(
        new Vehicle(
          vehicle.id,
          vehicle.brand,
          vehicle.brandCode,
          vehicle.range,
          vehicle.rangeCode,
          vehicle.model,
          vehicle.modelCode,
          vehicle.year,
          vehicle.introductionDate,
          vehicle.discontinuedDate,
          vehicle.galPic,
          vehicle.imageUrl != ""
            ? vehicle.imageUrl
            : require("@/assets/images/no-entry-for-motor-vehicle.png"),
          vehicle.diskDriveReference,
          vehicle.classType,
          vehicle.description,
          vehicle.capacity,
          vehicle.transmissionType,
          vehicle.fuelType,
          vehicle.driveType,
          vehicle.vehicleSummary
        )
      );
    }

    return vehicles;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getModels = async (brandName, year, rangeName) => {
  var vehicles = [];

  try {
    var result = await api.get(
      `/api/vehicles/getmodels?brandName=${brandName}&year=${year}&rangeName=${rangeName}`
    );

    for (let vehicle of result.data) {
      vehicles.push(
        new Vehicle(
          vehicle.id,
          vehicle.brand,
          vehicle.brandCode,
          vehicle.range,
          vehicle.rangeCode,
          vehicle.model,
          vehicle.modelCode,
          vehicle.year,
          vehicle.introductionDate,
          vehicle.discontinuedDate,
          vehicle.galPic,
          vehicle.imageUrl != ""
            ? vehicle.imageUrl
            : require("@/assets/images/no-entry-for-motor-vehicle.png"),
          vehicle.diskDriveReference,
          vehicle.classType,
          vehicle.description,
          vehicle.capacity,
          vehicle.transmissionType,
          vehicle.fuelType,
          vehicle.driveType,
          vehicle.vehicleSummary
        )
      );
    }

    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const queryVehicleServiceInterval = async (diskDriveReference, vehicleMileage) => {
  try {
    var serviceInterval = {};
    var result = await api.get(`/api/vehicles/queryVehicleServiceInterval?reference=${diskDriveReference}&vehicleMileage=${vehicleMileage}`);
    serviceInterval = result.data;
    return serviceInterval;
  }
  catch (error) {
    throw error;
  }
};

const downloadVehicleAttachment = async attachment => {
  try {
    var result = await api.get(`/api/vehicles/DownloadVehicleAttachment/${attachment.id}`, {
      headers: {
        "Content-Disposition": "attachment"
      },
      responseType: "blob"
    });

    fileDownload(result.data, attachment.attachmentFileName, result.headers.type);
  }
  catch (error) {
    throw error;
  }
};

export const vehicleService = {
  searchVehicles,
  vehicleLookup,
  getBrands,
  getYears,
  getRanges,
  getModels,
  queryVehicleServiceInterval,
  downloadVehicleAttachment
};
