import api from "./api";
import { formFunctions } from "@/helpers";

const getUser = async () => {
    try {
        var result = await api.get(`/api/user/GetApplicationUser`);
        return result;
    }
    catch (error) {
        return error;
    }
};

const upsertUser = async user => {
    try {
        var result = await api.post(`/api/User/PostApplicationUser`, user);
        return result;
    }
    catch (error) {
        return error;
    }
};

const getVehicles = async (payload) => {
    try {
        var result = await api.post(`/api/user/getVehicles`, payload);
        if (result && result.data && result.data.items && result.data.items.length > 0) {
            result.data.items.forEach(v => {
                if (v.vehicle.galPic == null) {
                    v.vehicle.imageUrl = require(`@/assets/images/no-entry-for-motor-vehicle.png`);
                }
            });
        }
        return result;
    }
    catch (error) {
        return error;
    }
};

const upsertVehicle = async (vehicleMapping, vehicleReportAttachment) => {
    try {
        var formData = formFunctions.toFormData(vehicleMapping);

        if (vehicleReportAttachment) {
            formData.append("vehicleReportAttachment", vehicleReportAttachment);
        }

        var result = await api.post(
            `/api/user/upsertVehicle`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
        );
        return result;
    }
    catch (error) {
        return error;
    }
};

const deleteVehicleMileage = async vehicleMappingMileageId => {
    try {
        var result = await api.delete(`/api/user/deleteVehicleMileage/${vehicleMappingMileageId}`);
        return result;
    }
    catch (error) {
        return error;
    }
};

const queryVehicleServiceSchedule = async vehicleMappingId => {
    try {
        var result = await api.get(`/api/user/queryVehicleServiceSchedule/${vehicleMappingId}`);
        return result;
    }
    catch (error) {
        return error;
    }
};

const queryUserRecentActions = async (page, searchTerm) => {
    try {
        var result = await api.get(`/api/user/queryUserRecentActions/${page}?searchTerm=${searchTerm}`);
        return result;
    }
    catch (error) {
        return error;
    }
};

const queryUserRecentMessages = async (page, isOpen, isSent) => {
    try {
        var result = await api.get(`/api/user/queryUserRecentMessages/${page}?isOpen=${isOpen}&isSent=${isSent}`);
        return result;
    }
    catch (error) {
        return error;
    }
};

const setMessageRead = async (payload) => {
    try {
        var result = await api.post(`/api/user/setMessageRead`, payload);
        return result;
    }
    catch (error) {
        return error;
    }
};

const upsertUserOrganizationBranch = async (payload) => {
    try {
        var result = await api.post(`/api/user/upsertOrganizationBranch`, payload);
        return result;
    }
    catch (error) {
        return error;
    }
};

export const userService = {
    getUser,
    upsertUser,
    getVehicles,
    upsertVehicle,
    deleteVehicleMileage,
    queryVehicleServiceSchedule,
    queryUserRecentActions,
    queryUserRecentMessages,
    setMessageRead,
    upsertUserOrganizationBranch
};