<template>
  <v-card color="white">
    <v-card-title class="smc-card-dialog-title-inset primary white--text py-2">
      <span v-if="workshop">{{ workshop.name }}</span>
      <span v-else>Workshop</span>
    </v-card-title>
    <v-card-text class="pt-3">
      <v-progress-circular v-if="loading" :size="25" indeterminate color="primary"></v-progress-circular>
      <v-sheet v-else>
        <div class="smc-card-avatar mx-auto">
        <img :src="workshop.logoUrl" class="smc-search-result-image" />
        <p>
          {{ `${workshop.address.streetNumber} ${workshop.address.streetName}` }}
          <br/>
          {{ workshop.address.suburb }}
          <br/>
          {{ workshop.address.city }}
          <br/>
          {{ workshop.address.province }}
          <br/>
          {{ workshop.address.country }}
          <br/>
          {{ workshop.address.postalCode }}
        </p>
      </div>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import { workshopService } from "@/services";

export default {
  name: "address-route-view",
  props: {
    loadProp: Boolean,
    workshopIdProp: Number
  },
  data: () => ({
    loading: true,
    workshop: undefined,
  }),
  async created() {
    await this.loadWorkshop();
  },
  watch: {
    loadProp() {
      this.loadWorkshop();
    }
  },
  methods: {
    async loadWorkshop() {
      if (this.loadProp && !this.workshop) {
        this.loading = true;
        this.workshop = await workshopService.getWorkshop(this.workshopIdProp);
        this.loading = false;
      }
    }
  }
}
</script>
