<template>
  <v-sheet>
    <v-row class="media-links">
      <v-spacer v-if="$vuetify.breakpoint.lgAndUp"></v-spacer>
      <v-spacer v-if="$vuetify.breakpoint.lgAndUp"></v-spacer>
      <v-col class="px-2">
        <v-hover v-slot="{ hover }" v-bind:key="enca.id">
          <a :href="enca.linkUrl" target="_blank">
            <v-img
              :src="hover ? enca.logoUrl : enca.logoUrlGrey"
              :lazy-src="hover ? enca.logoUrl : enca.logoUrlGrey"
              :width="enca.width"
              :height="enca.height"
              contain
              class="mx-auto"
            >
            </v-img>
          </a>
        </v-hover>
      </v-col>
      <v-col class="px-2">
        <v-hover v-slot="{ hover }" v-bind:key="offerzen.id">
          <a :href="offerzen.linkUrl" target="_blank">
            <v-img
              :src="hover ? offerzen.logoUrl : offerzen.logoUrlGrey"
              :lazy-src="hover ? offerzen.logoUrl : offerzen.logoUrlGrey"
              :width="offerzen.width"
              :height="offerzen.height"
              contain
              class="mx-auto"
            >
            </v-img>
          </a>
        </v-hover>
      </v-col>
      <v-col class="px-2">
        <v-hover v-slot="{ hover }" v-bind:key="disrupt.id">
          <a :href="disrupt.linkUrl" target="_blank">
            <v-img
              :src="hover ? disrupt.logoUrl : disrupt.logoUrlGrey"
              :lazy-src="hover ? disrupt.logoUrl : disrupt.logoUrlGrey"
              :width="disrupt.width"
              :height="disrupt.height"
              contain
              class="mx-auto"
              style="overflow: hidden"
            >
            </v-img>
          </a>
        </v-hover>
      </v-col>
      <v-col class="px-2">
        <v-hover v-slot="{ hover }" v-bind:key="ventureburn.id">
          <a :href="ventureburn.linkUrl" target="_blank">
            <v-img
              :src="hover ? ventureburn.logoUrl : ventureburn.logoUrlGrey"
              :lazy-src="hover ? ventureburn.logoUrl : ventureburn.logoUrlGrey"
              :width="ventureburn.width"
              :height="ventureburn.height"
              contain
              class="mx-auto"
            >
            </v-img>
          </a>
        </v-hover>
      </v-col>
      <v-col class="px-2">
        <v-hover v-slot="{ hover }" v-bind:key="newsCentral.id">
          <a :href="newsCentral.linkUrl" target="_blank">
            <v-img
              :src="hover ? newsCentral.logoUrl : newsCentral.logoUrlGrey"
              :lazy-src="hover ? newsCentral.logoUrl : newsCentral.logoUrlGrey"
              :width="newsCentral.width"
              :height="newsCentral.height"
              contain
              class="mx-auto"
            >
            </v-img>
          </a>
        </v-hover>
      </v-col>
      <v-col class="px-2">
        <v-hover v-slot="{ hover }" v-bind:key="afrikanHeroes.id">
          <a :href="afrikanHeroes.linkUrl" target="_blank">
            <v-img
              :src="hover ? afrikanHeroes.logoUrl : afrikanHeroes.logoUrlGrey"
              :lazy-src="hover ? afrikanHeroes.logoUrl : afrikanHeroes.logoUrlGrey"
              :width="afrikanHeroes.width"
              :height="afrikanHeroes.height"
              contain
              class="mx-auto"
            >
            </v-img>
          </a>
        </v-hover>
      </v-col>
      <v-col class="px-2">
        <v-hover v-slot="{ hover }" v-bind:key="techmoran.id">
          <a :href="techmoran.linkUrl" target="_blank">
            <v-img
              :src="hover ? techmoran.logoUrl : techmoran.logoUrlGrey"
              :lazy-src="hover ? techmoran.logoUrl : techmoran.logoUrlGrey"
              :width="techmoran.width"
              :height="techmoran.height"
              contain
              class="mx-auto"
            >
            </v-img>
          </a>
        </v-hover>
      </v-col>
      <v-col class="px-2" v-if="$vuetify.breakpoint.lgAndUp">
        <v-hover v-slot="{ hover }" v-bind:key="africaInc.id">
          <a :href="africaInc.linkUrl" target="_blank">
            <v-img
              :src="hover ? africaInc.logoUrl : africaInc.logoUrlGrey"
              :lazy-src="hover ? africaInc.logoUrl : africaInc.logoUrlGrey"
              :width="africaInc.width"
              :height="africaInc.height"
              contain
              class="mx-auto"
            >
            </v-img>
          </a>
        </v-hover>
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.lgAndUp"></v-spacer>
      <v-spacer v-if="$vuetify.breakpoint.lgAndUp"></v-spacer>
    </v-row>
    <v-divider style="max-width: 1185px" class="mx-auto"></v-divider>
  </v-sheet>
</template>

<script>
export default {
  name: "in-the-media",
  data: () => ({
    enca: {
      id: 1,
      logoUrl: require(`@/assets/images/in-the-media/enca.png`),
      logoUrlGrey: require(`@/assets/images/in-the-media/enca-grey.png`),
      height: "100",
      width: "100",
      linkUrl: "https://www.enca.com/shows/techub-06-december",
    },
    offerzen: {
      id: 2,
      logoUrl: require(`@/assets/images/in-the-media/offerzen.png`),
      logoUrlGrey: require(`@/assets/images/in-the-media/offerzen-grey.png`),
      height: "100",
      width: "100",
      linkUrl:
        "https://www.offerzen.com/blog/asking-questions-as-a-nontech-cofounder",
    },
    disrupt: {
      id: 3,
      logoUrl: require(`@/assets/images/in-the-media/disrupt.png`),
      logoUrlGrey: require(`@/assets/images/in-the-media/disrupt-grey.png`),
      height: "100",
      width: "100",
      linkUrl:
        "https://disrupt-africa.com/2020/11/13/sa-auto-tech-startup-launches-with-311k-seed-funding/",
    },
    ventureburn: {
      id: 4,
      logoUrl: require(`@/assets/images/in-the-media/ventureburn.jpg`),
      logoUrlGrey: require(`@/assets/images/in-the-media/ventureburn-grey.jpg`),
      height: "100",
      width: "140",
      linkUrl:
        "https://ventureburn.com/2020/09/new-car-tech-startup-launches-in-cape-town",
    },
    newsCentral: {
      id: 5,
      logoUrl: require(`@/assets/images/in-the-media/news-central.jpg`),
      logoUrlGrey: require(`@/assets/images/in-the-media/news-central-grey.jpg`),
      height: "100",
      width: "100",
      linkUrl: "https://www.youtube.com/watch?v=tgwl5mAtmic",
    },
    afrikanHeroes: {
      id: 6,
      logoUrl: require(`@/assets/images/in-the-media/afrikan-heroes.png`),
      logoUrlGrey: require(`@/assets/images/in-the-media/afrikan-heroes-grey.png`),
      height: "100",
      width: "100",
      linkUrl: "https://afrikanheroes.com/tag/aidan-quin/",
    },
    techmoran: {
      id: 7,
      logoUrl: require(`@/assets/images/in-the-media/techmoran.png`),
      logoUrlGrey: require(`@/assets/images/in-the-media/techmoran-grey.png`),
      height: "100",
      width: "100",
      linkUrl: "https://techmoran.com/2020/11/13/servicemycar-sa-auto-tech-startup-launches-with-311000-seed-funding/",
    },
    africaInc: {
      id: 8,
      logoUrl: require(`@/assets/images/in-the-media/africa-inc.png`),
      logoUrlGrey: require(`@/assets/images/in-the-media/africa-inc-grey.png`),
      height: "100",
      width: "100",
      linkUrl: "https://africaincmag.com/2020/11/17/south-africas-auto-tech-startup-launches-with-311k-seed-funding/",
    },
  }),
};
</script>

<style scoped>
.media-links a {
  display: inline-block;
}
</style>