import api from "./api"
import RegisterInvite from "@/models/register-invite";

const authenticate = async (payload) => {
    try {
        var result = await api.post(`/api/sites/${process.env.VUE_APP_SITE_ID}/authentication/authenticate`,
            payload
        );

        return result;
    }
    catch (error) {
        return error;
    }
};

const externalAuthenticate = async (payload) => {
    try {
        var result = await api.post(`/api/sites/${process.env.VUE_APP_SITE_ID}/authentication/externalauthenticate`, payload);
        return result;
    }
    catch (error) {
        return error;
    }
};

const register = async (payload) => {
    try {
        var result = await api.post(`/api/sites/${process.env.VUE_APP_SITE_ID}/authentication/register`,
            payload
        );
        return result;
    }
    catch (error) {
        return error;
    }
};

const refreshToken = async (payload) => {
    try {
        var result = await api.post(`/api/sites/${process.env.VUE_APP_SITE_ID}/authentication/refreshToken`, payload);
        return result;
    }
    catch (error) {
        return error;
    }
};

const confirmEmail = async (payload) => {
    try {
        var result = await api.post(`/api/sites/${process.env.VUE_APP_SITE_ID}/authentication/confirmemail`,
            payload
        );

        return result;
    }
    catch (error) {
        return error;
    }
};

const resendEmailConfirmation = async (payload) => {
    try {
        var result = await api.post(`/api/sites/${process.env.VUE_APP_SITE_ID}/authentication/resendconfirmation`,
            payload
        );

        return result;
    }
    catch (error) {
        return error;
    }
};

const addPassword = async (payload) => {
    try {
        var result = await api.post(`/api/sites/${process.env.VUE_APP_SITE_ID}/authentication/addpassword`,
            payload
        );

        return result;
    }
    catch (error) {
        return error;
    }
};

const changePassword = async (payload) => {
    try {
        var result = await api.post(`/api/sites/${process.env.VUE_APP_SITE_ID}/authentication/changepassword`,
            payload
        );

        return result;
    }
    catch (error) {
        return error;
    }
};

const forgotPassword = async (emailAddress) => {
    try {
        var result = await api.get(`/api/sites/${process.env.VUE_APP_SITE_ID}/authentication/forgotpassword?username=${emailAddress}`);

        return result;
    }
    catch (error) {
        return error;
    }
};

const forgotPasswordConfirm = async (payload) => {
    try {
        var result = await api.post(`/api/sites/${process.env.VUE_APP_SITE_ID}/authentication/forgotpasswordconfirm`,
            payload
        );

        return result;
    }
    catch (error) {
        return error;
    }
};

const searchInvite = async (payload) => {
    try {
        var result = await api.post(`/api/sites/${process.env.VUE_APP_SITE_ID}/authentication/searchInvite`, payload);

        return result;
    }
    catch (error) {
        return error;
    }
}

const getInvite = async (id, loggedInUserId) => {
    let invite = {};
    try {
        var result = await api.get(`/api/sites/${process.env.VUE_APP_SITE_ID}/authentication/getInvite/${id}?loggedInUserId=${loggedInUserId}`);
        invite = new RegisterInvite(
            result.data.firstName,
            result.data.lastName,
            result.data.email,
            result.data.emailReadonly,
            result.data.phoneNumber,
            result.data.phoneNumberReadonly,
            result.data.invalidInvite,
            result.data.invalidInviteError,
            result.data.requiresLogin,
            result.data.hasPasswordLogin,
            result.data.hasGoogleLogin,
            result.data.requiresConsent,
            // this policy might be refactored generic in the future
            result.data.innovationPolicy
        );
        return invite;
    }
    catch (error) {
        throw error;
    }
};

const consentInvite = async (id) => {
    try {
        var result = await api.post(`/api/sites/${process.env.VUE_APP_SITE_ID}/authentication/consentInvite/${id}`);
        return result;
    }
    catch (error) {
        return error;
    }
};

const inviteRegister = async (id, payload) => {
    try {
        var result = await api.post(`/api/sites/${process.env.VUE_APP_SITE_ID}/authentication/inviteRegister/${id}`,
            payload
        );
        return result;
    }
    catch (error) {
        return error;
    }
};

export const authService = {
    authenticate,
    externalAuthenticate,
    register,
    refreshToken,
    confirmEmail,
    resendEmailConfirmation,
    addPassword,
    changePassword,
    forgotPassword,
    forgotPasswordConfirm,
    searchInvite,
    getInvite,
    consentInvite,
    inviteRegister
}