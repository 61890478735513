import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import { user } from "./modules/user.module";
import { contactSpeedDial } from "./modules/contact-speed-dial.module";
import { quoteRequestCreate } from "./modules/quote-request-create.module";
import { pwaInstall } from "./modules/pwa-install.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    contactSpeedDial,
    quoteRequestCreate,
    pwaInstall
  },
  plugins: [createPersistedState()]
});
