var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stepper-content',{attrs:{"step":"2"}},[_c('ValidationObserver',{ref:"step2formValidationObserver"},[_c('v-form',{ref:"step2form",attrs:{"lazy-validation":""},model:{value:(_vm.step2Valid),callback:function ($$v) {_vm.step2Valid=$$v},expression:"step2Valid"}},[_c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"pt-1 mt-1"}),(_vm.quoteType != _vm.constants.policyLabel)?_c('div',[_c('p',[_vm._v(" Add any additional repairs your vehicle may need by selecting the type of repair from the list on the on the right. If you are unsure of which repair to select or if your required repair is not listed, please select the “explain issue” option. ")]),_c('v-divider',{attrs:{"role":"presentation"}}),_c('div',{staticClass:"pt-1 mt-1"}),_c('v-btn-toggle',{staticClass:"smc-service-type",style:(this.$vuetify.breakpoint.smAndDown
                ? 'display: inline-block'
                : ''),attrs:{"rounded":"","dense":"","active-class":"primary"},model:{value:(_vm.quoteRequest.partsPreference),callback:function ($$v) {_vm.$set(_vm.quoteRequest, "partsPreference", $$v)},expression:"quoteRequest.partsPreference"}},[_c('v-btn',{staticClass:"smc-text-button my-1",attrs:{"id":"createQutoe-partsTypcurrentStep","value":"Original parts","width":"150"}},[_vm._v(" Original parts ")]),_c('v-btn',{staticClass:"smc-text-button my-1",attrs:{"id":"createQutoe-partsType2","value":"Generic parts","width":"150"}},[_vm._v(" Generic parts ")]),_c('v-btn',{staticClass:"smc-text-button my-1",attrs:{"id":"createQutoe-partsType3","value":"I don't mind","width":"150"}},[_vm._v(" I don't mind ")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-icon',_vm._g({attrs:{"color":hover ? 'primary' : 'secondary'}},on),[_vm._v(" mdi-help-circle ")])]}}],null,true)})]}}],null,false,2078727581)},[_c('span',[_vm._v(" Original parts refer to parts specifically made and sold by your vehicle's manufacturer. "),_c('br'),_vm._v("Generic parts are good quality alternatives. ")])]),_c('v-expand-transition',[(_vm.quoteRequest.partsPreference == 'Original parts')?_c('div',[_c('div',{staticClass:"py-1 my-1"}),_c('v-card-title',{staticClass:"smc-card-reduced-heading primary white--text mb-4"},[_vm._v(" License disk information ")]),_c('v-card-subtitle',{staticClass:"mt-2"},[_vm._v(" We require the following information because you requested original parts. Our reputable workshop network require at least a VIN number in order to order original OEM parts. ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"9","md":"11"}},[_c('ValidationProvider',{attrs:{"mode":"lazy","name":"Vehicle VIN no","rules":("anyRequired:@licenseDiskPhoto|min:17|max:" + _vm.vinLength)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"createQuote-vehicleVinNumber","error-messages":errors,"label":"Vehicle VIN no","outlined":"","rounded":"","prepend-inner-icon":"mdi-barcode-scan","counter":_vm.vinLength},model:{value:(_vm.quoteRequest.vehicleVinNumber),callback:function ($$v) {_vm.$set(_vm.quoteRequest, "vehicleVinNumber", $$v)},expression:"quoteRequest.vehicleVinNumber"}})]}}],null,false,2804729084)}),(
                          _vm.quoteRequest.vehicleVinNumber &&
                          _vm.quoteRequest.vehicleVinNumber.length == _vm.vinLength &&
                          !_vm.validateVin(_vm.quoteRequest.vehicleVinNumber)
                        )?_c('v-alert',{staticClass:"text-left",attrs:{"type":"info","outlined":"","border":"left"}},[_vm._v(" It looks like your VIN number might not be correct. "),_c('br'),_vm._v(" Please note that the following letters never appear in VIN numbers: I, O, Q "),_c('br'),_vm._v(" If you are sure that the VIN number is correct, you can proceed ")]):_vm._e()],1),(this.$vuetify.breakpoint.smAndUp)?_c('v-col',{staticClass:"pt-6 px-0"},[_c('image-tooltip',{attrs:{"imageNameProp":'license_disk_vin.png'}})],1):_vm._e()],1)],1),_c('v-col',{staticClass:"divider-column shrink"},[(this.$vuetify.breakpoint.mdAndUp)?_c('v-row',[_c('v-col',[_c('v-divider',{attrs:{"role":"presentation","vertical":""}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-chip',{staticClass:"white--text",attrs:{"color":"darkGrey"}},[_vm._v("OR")])],1)],1),(this.$vuetify.breakpoint.mdAndUp)?_c('v-row',[_c('v-col',[_c('v-divider',{attrs:{"role":"presentation","vertical":""}})],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('ValidationProvider',{attrs:{"name":"License disk photo","id":"licenseDiskPhoto","rules":"image|size:10000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-file-input',{attrs:{"id":"createQuote-licenseDiskPhoto","error-messages":errors,"label":"License disk photo","prepend-inner-icon":"mdi-paperclip","prepend-icon":"","outlined":"","rounded":"","counter":"","show-size":"","accept":"image/*"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var index = ref.index;
                        var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"primary accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}],null,true),model:{value:(_vm.quoteRequest.LicenseDiskPhoto),callback:function ($$v) {_vm.$set(_vm.quoteRequest, "LicenseDiskPhoto", $$v)},expression:"quoteRequest.LicenseDiskPhoto"}})]}}],null,false,1513192865)}),_c('v-img',{attrs:{"src":_vm.licenseDiskPhotoPreview,"lazy-src":_vm.licenseDiskPhotoPreview,"max-height":"200px","contain":""}})],1)],1),_c('v-divider',{attrs:{"role":"presentation"}}),_c('div',{staticClass:"py-1 my-1"})],1):_vm._e()]),_c('div',{staticClass:"pt-1 mt-1"})],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"sticky-top animate-transition mx-1",class:!_vm.showRepairList ? 'added-repairs-center' : '',attrs:{"min-width":"268","raised":""}},[_c('v-card-title',{staticClass:"white--text primary smc-card-reduced-heading",attrs:{"color":"white"}},[_c('span',[_vm._v(" What to Expect ")])]),_c('v-list',{attrs:{"tile":"","flat":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-scroll-x-transition',{ref:"repairItemList",attrs:{"group":""}},[(_vm.hasMaintenanceService)?_c('v-list-item',{key:-3},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-engine")])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-left body-2"},[_vm._v(" "+_vm._s(_vm.annualServiceType)+" "),(_vm.serviceIntervalLoading)?_c('v-progress-circular',{staticClass:"float-right",attrs:{"size":20,"color":"primary","indeterminate":""}}):_vm._e()],1)],1)],1):_vm._e(),(
                        //quoteType == constants.repairLabel &&
                        _vm.servicesCount == 0
                      )?_c('v-list-item',{key:-1},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-wrench")])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-left body-2"},[_vm._v(" Add a repair item from the list ")])],1)],1):_vm._e(),_vm._l((_vm.leafFilteredRepairItemsTree),function(item){return [(item.name != 'Maintenance Service')?_c('v-list-item-group',{key:item.id},[_c('v-list-item',{class:!item.repairItemTemplateItems || item.repairItemTemplateItems.length == 0 ? 'mb-2' : ''},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c('v-list-item-content',{staticClass:"py-0 pr-2"},[_c('v-list-item-title',{staticClass:"text-left body-2"},[_c('span',[_c('label',{ref:'addedServiceItem' + item.id,refInFor:true,staticClass:"text-wrap",attrs:{"id":'addedServiceItem' + item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])]),(
                                  item.name
                                    .toLowerCase()
                                    .includes('tyre replacement')
                                )?_c('image-tooltip',{attrs:{"imageNameProp":"tyre-size-img.png"}}):_vm._e(),(item.hasQuery)?_c('ValidationProvider',{attrs:{"name":("addedServiceDetails" + (item.id)),"rules":"required:true"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('v-textarea',{staticClass:"smc-textinput-dense",attrs:{"id":("createQuote-addedServices-details-" + (item.id)),"error-messages":errors,"hint":item.query,"persistent-hint":"","rows":"1","dense":"","auto-grow":"","clearable":"","counter":_vm.$vuetify.breakpoint.smAndUp,"outlined":"","required":"","maxLength":"500"},model:{value:(item.queryAnswer),callback:function ($$v) {_vm.$set(item, "queryAnswer", $$v)},expression:"item.queryAnswer"}})]}}],null,true)}):_vm._e()],1)],1),(item.repairItemTemplateItems && item.repairItemTemplateItems.length != item.baseRepairItemTemplateItems.length)?_c('v-btn',{attrs:{"id":("createQuote-addedServices-remove-" + (item.id)),"icon":""},on:{"click":function($event){return _vm.restoreRepairItemTemplateItems(item)}}},[_c('v-icon',[_vm._v("mdi-restore")])],1):_vm._e(),_c('v-btn',{attrs:{"id":("createQuote-addedServices-remove-" + (item.id)),"icon":""},on:{"click":function($event){return _vm.removeRepairItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),(
                            item.repairItemTemplateItems &&
                            item.repairItemTemplateItems.length > 0
                          )?_c('v-list-item-group',{staticClass:"mb-6"},_vm._l((item.repairItemTemplateItems),function(template,index){return _c('v-list-item',{key:index,staticClass:"ml-6 service-interval-item"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"text-left body-2"},[_vm._v(" "+_vm._s(template.description)+" ")])],1),(_vm.quoteType != _vm.constants.policyLabel)?_c('v-list-item-avatar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeTemplateItem(item, template)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)}),1):_vm._e()],1):_vm._e()]})],2)],1)],1),_c('v-divider'),(_vm.quoteType == _vm.constants.serviceLabel)?_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","right":"","rounded":"","small":""},on:{"click":function($event){_vm.showRepairList = !_vm.showRepairList}}},[(!_vm.showRepairList)?_c('span',[_vm._v(" Show repairs ")]):_c('span',[_vm._v(" Hide repairs ")])])],1)],1)],1):_vm._e()],1)],1),(_vm.quoteType !== _vm.constants.policyLabel)?_c('v-slide-x-reverse-transition',[(_vm.showRepairList)?_c('v-col',[_c('v-card',{staticClass:"mx-1",attrs:{"tile":""}},[_c('v-sheet',{staticClass:"pa-2"},[_c('v-text-field',{attrs:{"id":"createQuote-treeSearch","label":"Search for service","rounded":"","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.treeSearch),callback:function ($$v) {_vm.treeSearch=$$v},expression:"treeSearch"}})],1),_c('v-treeview',{staticClass:"repairs-treeview",attrs:{"id":"createQuote-servicesTree","items":_vm.repairItemsTreeModel,"selection-type":"independent","selected-color":"primary","item-text":"displayName","search":_vm.treeSearch,"selectable":"","hoverable":"","open-on-click":"","return-object":"","rounded":"","transition":"","multiple-active":true},scopedSlots:_vm._u([{key:"label",fn:function(ref){
                          var item = ref.item;
return [_c('v-btn',{staticClass:"float-left text-wrap text-none body-2 font-weight-regular smc-text-button",attrs:{"id":("createQuote-services-" + (item.id)),"text":"","height":_vm.$vuetify.breakpoint.smAndDown ? 60 : 36},on:{"click":function($event){return _vm.treeItemClicked(item)}}},[_vm._v(" "+_vm._s(item.displayName)+" ")])]}},{key:"append",fn:function(ref){
                          var item = ref.item;
return [(item.trunk)?_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e()]}}],null,false,1014434145),model:{value:(_vm.quoteRequest.items),callback:function ($$v) {_vm.$set(_vm.quoteRequest, "items", $$v)},expression:"quoteRequest.items"}})],1)],1):_vm._e()],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"d-none d-md-flex",attrs:{"cols":"2"}}),_c('v-col',[_c('v-expand-transition',[(_vm.noServicesError)?_c('v-alert',{staticClass:"mb-0",attrs:{"border":"left","type":"error"}},[_vm._v(" Please add at least one service or repair to your request. ")]):_vm._e()],1)],1),_c('v-col',{staticClass:"d-none d-md-flex",attrs:{"cols":"2"}})],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"smc-text-button smc-medium-button mx-1 my-1",attrs:{"id":"createQuote-step2Back","rounded":"","color":"secondary","disabled":_vm.stepLoading},on:{"click":function($event){return _vm.goStep(1)}}},[(!_vm.stepLoading)?_c('span',[_vm._v(" Back ")]):_c('v-progress-circular',{attrs:{"size":25,"indeterminate":"","color":"secondary"}})],1),_c('v-btn',{staticClass:"smc-text-button smc-medium-button mx-1 my-1",attrs:{"id":"createQuote-step2Next","rounded":"","color":"primary","disabled":!_vm.step2Valid || _vm.stepLoading},on:{"click":function($event){return _vm.stepForward(3)}}},[(!_vm.stepLoading)?_c('span',{domProps:{"textContent":_vm._s('Next')}}):_c('v-progress-circular',{attrs:{"size":25,"indeterminate":"","color":"primary"}})],1)],1)],1)],1)],1)],1),_c('v-snackbar',{class:_vm.$vuetify.breakpoint.xsOnly ? 'pb-0' : 'pb-6',attrs:{"color":"primary","bottom":""},model:{value:(_vm.addedSnackbar),callback:function ($$v) {_vm.addedSnackbar=$$v},expression:"addedSnackbar"}},[_vm._v(" "+_vm._s(_vm.addedItemName)+" has been added to your request. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }