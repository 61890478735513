<template>
  <div>
    <div class="smc-car-flag-container" :class="carAnimation">
      <div class="smc-car-flag-wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          class="smc-car-flag-points"
        >
          <polygon
            points="0 0, 100 0, 100 100, 0 100, 50, 50"
            :fill="flagTailsColour"
          />
        </svg>
        <div class="smc-car-flag" :class="`${flagTextColour} ${flagColour}`">
          {{ flagText }}
        </div>
      </div>
      <div class="smc-car-flag-vehicle-wrapper">
        <v-icon :color="carColour" large class="smc-car-flag-vehicle">
          mdi-car-side
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    flagTextProp: String,
    flagTextColourProp: String,
    flagColourProp: String,
    flagTailsColourProp: String,
    carColourProp: String,
    carAnimationProp: String
  },
  computed: {
    flagText() {
      if (!this.flagTextProp) {
        return "";
      }
      return this.flagTextProp;
    },
    flagTextColour() {
      if (!this.flagTextColourProp) {
        return "white--text";
      }
      return this.flagTextProp;
    },
    flagColour() {
      if (!this.flagColourProp) {
        return "primary";
      }
      return this.flagColourProp;
    },
    flagTailsColour() {
      if (!this.flagTailsColourProp) {
        return "#ed3237";
      }
      return this.flagTailsColourProp;
    },
    carColour() {
      if (!this.carColourProp) {
        return "primary";
      }
      return this.carColourProp;
    },
    carAnimation() {
      if (!this.carAnimationProp) {
        return "smc-car-flag-container-driving";
      }
      return this.carAnimationProp;
    }
  }
};
</script>

<style scoped>
.smc-car-flag-container {
  position: relative;
}

.smc-car-flag-container-driving:hover {
  animation: smc-car-driving 1s infinite;
}

.smc-car-flag-container-sailing:hover {
  animation: smc-car-sailing 1s infinite;
}

.smc-car-flag-wrapper {
  position: absolute;
  top: 2px;
  right: 32px;
  height: 13.5px;
}

.smc-car-flag-wrapper::after {
  content: " ";
  border-right: 2px solid silver;
  height: 6px;
  position: absolute;
  right: 0;
}

.smc-car-flag {
  font-size: 9px;
  padding: 0 4px;
  height: 13.5px;
  line-height: 13.5px;
  white-space: nowrap;
}

.smc-car-flag-points {
  height: 13.5px;
  position: absolute;
  left: -10px;
}

.smc-car-flag-vehicle {
  bottom: -2px;
}

@keyframes smc-car-driving {
  0% {
    top: 0;
    transform: none;
  }

  10% {
    top: -1px;
    transform: none;
  }

  20% {
    top: 0;
    transform: 0;
  }

  30% {
    top: 0;
    transform: rotate(-2deg);
  }

  40% {
    top: 0;
    transform: none;
  }

  50% {
    top: -1px;
    transform: none;
  }

  60% {
    top: 0;
    transform: none;
  }

  70% {
    top: 0;
    transform: rotate(1deg);
  }

  80% {
    top: 0;
    transform: none;
  }

  90% {
    top: -1.5px;
    transform: rotate(1deg);
  }

  100% {
    top: 0;
    transform: rotate(1deg);
  }
}

@keyframes smc-car-sailing {
  0% {
    top: 0;
    transform: none;
  }

  5.2775% {
    top: 0;
    transform: rotate(0.5deg);
  }

  10.555% {
    top: 0;
    transform: rotate(1deg);
  }

  15.8325% {
    top: 0;
    transform: rotate(1.5deg);
  }

  21.11% {
    top: 0;
    transform: rotate(2deg);
  }

  26.3875% {
    top: 0.5px;
    transform: rotate(2deg);
  }

  31.665% {
    top: 1px;
    transform: rotate(2deg);
  }

  36.9425% {
    top: 1px;
    transform: rotate(1.5deg);
  }

  42.22% {
    top: 1px;
    transform: rotate(1deg);
  }

  47.4975% {
    top: 1px;
    transform: rotate(0.5deg);
  }

  52.775% {
    top: 1px;
    transform: none;
  }

  58.0525% {
    top: 1px;
    transform: rotate(-0.5deg);
  }

  63.33% {
    top: 1px;
    transform: rotate(-1deg);
  }

  68.6075% {
    top: 1px;
    transform: rotate(-1.5deg);
  }

  73.885% {
    top: 1px;
    transform: rotate(-2deg);
  }

  79.1625% {
    top: 0.5px;
    transform: rotate(-2deg);
  }

  84.44% {
    top: 0;
    transform: rotate(-2deg);
  }

  89.7175% {
    top: 0;
    transform: rotate(-1.5deg);
  }

  94.995% {
    top: 0;
    transform: rotate(-1deg);
  }

  100% {
    top: 0;
    transform: rotate(-0.5deg);
  }
}
</style>
