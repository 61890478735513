export default class DistanceMatrix {
    constructor(
        distance,
        distanceText,
        duration,
        durationText,
        durationInTraffic,
        durationInTrafficText,
    ) {
        this.distance = distance;
        this.distanceText = distanceText;
        this.duration = duration;
        this.durationText = durationText;
        this.durationInTraffic = durationInTraffic;
        this.durationInTrafficText = durationInTrafficText;
    }
}