<template>
  <v-sheet>
    <div
      class="home container"
      :class="$vuetify.breakpoint.xsOnly ? 'px-0' : ''"
    >
      <background-parallax />

      <div class="mt-12 pt-12" style="position: relative; min-height: 415px">
        <vehicle-search
          v-if="showVehicleSearch"
          v-bind:vehicleMappingIdProp="vehicleMappingIdProp"
          v-bind:showExpandedProp="true"
          v-bind:noSearchProp="
            (vehicleMappingProp != undefined &&
              vehicleMappingProp.vehicle != undefined) == true
          "
          v-bind:garageVehicleProp="vehicleMappingProp"
          v-bind:vehicleModel="
            vehicleMappingProp && vehicleMappingProp.vehicle
              ? vehicleMappingProp.vehicle
              : undefined
          "
          v-bind:policyModelProp="
            vehicleMappingProp && vehicleMappingProp.innovationPolicy
              ? vehicleMappingProp.innovationPolicy
              : undefined
          "
          v-bind:isPolicyQuoteProp="isPolicyQuoteProp"
        />
      </div>

      <div class="mx-4 pa-4" />
    </div>

    <what-we-do />
  </v-sheet>
</template>

<script>
import vehicleSearch from "@/components/vehicle-search";
import backgroundParallax from "@/components/widgets/background-parallax";
import whatWeDo from "@/components/widgets/what-we-do";

export default {
  name: "new-quote",
  components: {
    vehicleSearch,
    backgroundParallax,
    whatWeDo,
  },
  props: {
    vehicleMappingIdProp: String,
    vehicleMappingProp: Object,
    isPolicyQuoteProp: Boolean,
  },
  data: () => ({
    showVehicleSearch: true
  }),
  watch: {
    vehicleMappingIdProp: function () {
      this.showVehicleSearch = false;
      this.initializePage();
      this.$nextTick(() => {
        this.showVehicleSearch = true;
      });
    },
  },
  created() {
    this.initializePage();
  },
  methods: {
    initializePage() {
      var quoteRequest =
        this.$store.getters["quoteRequestCreate/getQuoteRequest"];
      if (this.vehicleMappingProp && this.vehicleMappingProp.vehicle) {
        if (quoteRequest && quoteRequest.vehicle) {
          if (quoteRequest.vehicle.id != this.vehicleMappingProp.vehicle.id) {
            this.$store.dispatch("quoteRequestCreate/resetQuoteRequest");
          }
        }
      }
    },
  },
};
</script>