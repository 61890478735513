var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('video-background',{ref:"videoBackgroundPlayer",staticStyle:{"max-height":"700px","height":"100vh","position":"absolute","left":"0","top":"0"},attrs:{"src":require('@/assets/videos/cars-backgournd-large.mp4'),"poster":require('@/assets/images/cars-background-poster.jpg'),"sources":[
    {
      src: require('@/assets/videos/cars-backgournd-small.mp4'),
      res: 900,
      autoplay: true,
      poster: require('@/assets/images/cars-background-poster.jpg'),
    },
    /*{
      src: require('@/assets/videos/cars-backgournd-small.mp4'),
      res: 601,
      autoplay: true,
      poster: require('@/assets/images/cars-background-poster.jpg'),
    },*/
    {
      res: 600,
      poster: require('@/assets/images/cars-mobile-background-poster.jpg'),
    } ]}})}
var staticRenderFns = []

export { render, staticRenderFns }