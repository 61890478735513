var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-row',{staticClass:"media-links"},[(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-spacer'):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-spacer'):_vm._e(),_c('v-col',{staticClass:"px-2"},[_c('v-hover',{key:_vm.enca.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":_vm.enca.linkUrl,"target":"_blank"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":hover ? _vm.enca.logoUrl : _vm.enca.logoUrlGrey,"lazy-src":hover ? _vm.enca.logoUrl : _vm.enca.logoUrlGrey,"width":_vm.enca.width,"height":_vm.enca.height,"contain":""}})],1)]}}])})],1),_c('v-col',{staticClass:"px-2"},[_c('v-hover',{key:_vm.offerzen.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":_vm.offerzen.linkUrl,"target":"_blank"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":hover ? _vm.offerzen.logoUrl : _vm.offerzen.logoUrlGrey,"lazy-src":hover ? _vm.offerzen.logoUrl : _vm.offerzen.logoUrlGrey,"width":_vm.offerzen.width,"height":_vm.offerzen.height,"contain":""}})],1)]}}])})],1),_c('v-col',{staticClass:"px-2"},[_c('v-hover',{key:_vm.disrupt.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":_vm.disrupt.linkUrl,"target":"_blank"}},[_c('v-img',{staticClass:"mx-auto",staticStyle:{"overflow":"hidden"},attrs:{"src":hover ? _vm.disrupt.logoUrl : _vm.disrupt.logoUrlGrey,"lazy-src":hover ? _vm.disrupt.logoUrl : _vm.disrupt.logoUrlGrey,"width":_vm.disrupt.width,"height":_vm.disrupt.height,"contain":""}})],1)]}}])})],1),_c('v-col',{staticClass:"px-2"},[_c('v-hover',{key:_vm.ventureburn.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":_vm.ventureburn.linkUrl,"target":"_blank"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":hover ? _vm.ventureburn.logoUrl : _vm.ventureburn.logoUrlGrey,"lazy-src":hover ? _vm.ventureburn.logoUrl : _vm.ventureburn.logoUrlGrey,"width":_vm.ventureburn.width,"height":_vm.ventureburn.height,"contain":""}})],1)]}}])})],1),_c('v-col',{staticClass:"px-2"},[_c('v-hover',{key:_vm.newsCentral.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":_vm.newsCentral.linkUrl,"target":"_blank"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":hover ? _vm.newsCentral.logoUrl : _vm.newsCentral.logoUrlGrey,"lazy-src":hover ? _vm.newsCentral.logoUrl : _vm.newsCentral.logoUrlGrey,"width":_vm.newsCentral.width,"height":_vm.newsCentral.height,"contain":""}})],1)]}}])})],1),_c('v-col',{staticClass:"px-2"},[_c('v-hover',{key:_vm.afrikanHeroes.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":_vm.afrikanHeroes.linkUrl,"target":"_blank"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":hover ? _vm.afrikanHeroes.logoUrl : _vm.afrikanHeroes.logoUrlGrey,"lazy-src":hover ? _vm.afrikanHeroes.logoUrl : _vm.afrikanHeroes.logoUrlGrey,"width":_vm.afrikanHeroes.width,"height":_vm.afrikanHeroes.height,"contain":""}})],1)]}}])})],1),_c('v-col',{staticClass:"px-2"},[_c('v-hover',{key:_vm.techmoran.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":_vm.techmoran.linkUrl,"target":"_blank"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":hover ? _vm.techmoran.logoUrl : _vm.techmoran.logoUrlGrey,"lazy-src":hover ? _vm.techmoran.logoUrl : _vm.techmoran.logoUrlGrey,"width":_vm.techmoran.width,"height":_vm.techmoran.height,"contain":""}})],1)]}}])})],1),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-col',{staticClass:"px-2"},[_c('v-hover',{key:_vm.africaInc.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('a',{attrs:{"href":_vm.africaInc.linkUrl,"target":"_blank"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":hover ? _vm.africaInc.logoUrl : _vm.africaInc.logoUrlGrey,"lazy-src":hover ? _vm.africaInc.logoUrl : _vm.africaInc.logoUrlGrey,"width":_vm.africaInc.width,"height":_vm.africaInc.height,"contain":""}})],1)]}}],null,false,3666778092)})],1):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-spacer'):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-spacer'):_vm._e()],1),_c('v-divider',{staticClass:"mx-auto",staticStyle:{"max-width":"1185px"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }