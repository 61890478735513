import api from "./api";
import Region from "@/models/region";

const getRegions = async () => {
  var regions = [];

  try {
    var result = await api.get(`/api/regions`);

    for (let region of result.data) {
      regions.push(
        new Region(
          region.id,
          region.name,
          region.coordinates,
          region.dateCreatedUtc,
          region.dateModifiedUtc
        )
      );
    }

    return regions;
  } catch (error) {
    return regions;
  }
};

export const regionService = { getRegions };
