export default class Workshop {
    constructor(
        id,
        name,
        isPosh,
        rating,
        taxNumber,
        registrationNumber, 
        address,
        logoUrl,
        hasPayfastAccount,
        paystackSubaccountId,
        dateCreated,
        dateCreatedUtc,
        distanceMatrix,
        workshopReviews,
        isVatRegistered,
        workshopContact,
        workshopCertifications,
        bankAccount
    ) {
        this.id = id;
        this.name = name;
        this.isPosh = isPosh;
        this.rating = rating;
        this.taxNumber = taxNumber;
        this.registrationNumber = registrationNumber;
        this.address = address;
        this.logoUrl = logoUrl;
        this.hasPayfastAccount = hasPayfastAccount;
        this.paystackSubaccountId = paystackSubaccountId;
        this.dateCreated = dateCreated;
        this.dateCreatedUtc = dateCreatedUtc;
        this.distanceMatrix = distanceMatrix;
        this.workshopReviews = workshopReviews;
        this.isVatRegistered = isVatRegistered;
        this.workshopContact = workshopContact;
        this.workshopCertifications = workshopCertifications;
        this.bankAccount = bankAccount;
    }
}