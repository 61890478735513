<template>
  <div>
    <v-select
      label="Branch"
      :loading="loading"
      class="smc-organization-branch-picker"
      v-model="selectedOrganizationBranch"
      :items="organizationBranches"
      outlined
      dense
      :menu-props="{ nudgeBottom: 40 }"
      item-value="organizationBranchId"
      item-text="organizationBranchName"
      :return-object="true"
    ></v-select>
  </div>
</template>

<script>
import _ from "lodash";
import { userService } from "@/services";

export default {
  name: "organization-branch-picker",
  data: () => ({
    loading: false,
    selectedOrganizationBranch: undefined,
    organizationBranches: [],
  }),
  computed: {
    organization() {
      return this.$store.getters["user/getOrganization"];
    },
    organizationBranch() {
      return this.organization.organizationBranches[0];
    },
  },
  mounted() {
    this.selectedOrganizationBranch = {
      organizationBranchId: this.organizationBranch.id,
      organizationBranchName: this.organizationBranch.branchName,
    };
    this.organizationBranches.push(this.selectedOrganizationBranch);
    this.organizationBranches =
      this.$store.getters["user/getOrganizationBranchOptions"];

    // Check if organizationBranch is not in organizationBranches, then push it
    if (
      !_.some(this.organizationBranches, {
        organizationBranchId: this.organizationBranch.id,
      })
    ) {
      this.organizationBranches.push({
        organizationBranchId: this.organizationBranch.id,
        organizationBranchName: this.organizationBranch.branchName,
      });
    }

    this.organizationBranches = _.sortBy(this.organizationBranches, [
      "organizationBranchName",
    ]);
  },
  watch: {
    async selectedOrganizationBranch(newVal) {
      if (this.organizationBranch.id !== newVal.organizationBranchId) {
        await this.updateOrganizationBranch();
      }
    },
  },
  methods: {
    async updateOrganizationBranch() {
      this.loading = true;
      var result = await userService.upsertUserOrganizationBranch({
        authUserId: this.$store.getters["user/getUserId"],
        organizationBranchId:
          this.selectedOrganizationBranch.organizationBranchId,
      });
      if (result.status == 200) {
        let organization = _.cloneDeep(this.organization);
        organization.organizationBranches = [result.data];
        this.$store.dispatch("user/setOrganization", organization);
        this.$store.dispatch("quoteRequestCreate/resetQuoteRequest");
        if (
          this.$router.currentRoute.name == "quote-requests" ||
          this.$router.currentRoute.name == "quotes"
        ) {
          this.$router.push("/active-requests");
        } else {
          this.$router.go(this.$router.currentRoute);
        }
      }
      this.loading = false;
    },
  },
};
</script>