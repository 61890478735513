<template>
  <v-card
    id="smc-vehicle-search"
    class="smc-card-transparent smc-card-borderless pt-4"
  >
    <v-card-text class="pb-2">
      <div class="smc-search-back"></div>
      <div v-if="!noSearch">
        <v-expand-transition>
          <div v-if="!searchInitialized">
            <p
              class="text-center title white--text font-weight-medium mx-auto"
              style="max-width: 565px"
            >
              <span
                class="font-weight-black title"
                style="font-size: 1.375rem !important"
              >
                Get & Compare quotes
              </span>
              for your vehicle
              <span class="primary--text font-weight-black headline">
                service
              </span>
              or
              <span class="primary--text font-weight-black headline">
                repair
              </span>
              from workshops in your area!
            </p>

            <v-btn
              rounded
              class="mt-3"
              color="primary"
              @click="updateSearchInitialized(true)"
              :loading="searchLoading"
            >
              Click to start
            </v-btn>
            <div id="flip-container" @click="flip()">
              <v-expand-transition>
                <div id="flip-container-front" v-if="flipped">
                  <p
                    class="text-center title white--text font-weight-medium mx-auto mt-6 mb-0"
                  >
                    <roller
                      class="primary--text font-weight-black headline"
                      :text="rollerCounter"
                      :isNumberFormat="true"
                      :transition="2"
                    ></roller>
                    happy customers since
                    <br v-if="$vuetify.breakpoint.xsOnly" />
                    1 September 2020
                  </p>
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div id="flip-container-back" v-if="!flipped">
                  <p
                    class="text-center title white--text font-weight-medium mx-auto mt-6 mb-0"
                  >
                    Our customers have saved up to
                    <br v-if="$vuetify.breakpoint.xsOnly" />
                    <roller
                      class="primary--text font-weight-black headline"
                      :text="'36.5'"
                      :isNumberFormat="true"
                      :transition="2"
                    ></roller>
                    <span class="primary--text font-weight-black headline">
                      %
                    </span>
                    on their vehicle services and repairs
                  </p>
                </div>
              </v-expand-transition>
            </div>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-if="searchInitialized">
            <v-row>
              <v-col v-if="showGarage">
                <p class="text-center title white--text font-weight-medium">
                  Select a vehicle in your garage
                </p>
                <v-combobox
                  id="searchVehicle-my-garage"
                  class="smc-textfield-tall"
                  item-text="name"
                  item-value="id"
                  v-model="selectedGarageVehicle"
                  @change="selectedGarageVehicleChange"
                  ref="searchVehicleMyGarage"
                  placeholder="My Garage"
                  :items="myGarageVehicles"
                  :menu-props="{
                    allowOverflow: true,
                    closeOnClick: false,
                    closeOnContentClick: false,
                    disableKeys: true,
                    openOnClick: false,
                    maxHeight: 500,
                  }"
                  chips
                  hide-details
                  return-object
                  no-filter
                  :clearable="$vuetify.breakpoint.smAndUp"
                  outlined
                  rounded
                >
                  <template
                    v-slot:prepend-inner
                    v-if="$vuetify.breakpoint.smAndUp"
                  >
                    <v-icon color="primary">mdi-home</v-icon>
                  </template>

                  <template
                    v-slot:item="data"
                    v-bar="{ preventParentScroll: true, scrollThrottle: 30 }"
                  >
                    <img
                      v-if="$vuetify.breakpoint.smAndUp"
                      class="smc-search-image"
                      :src="data.item.vehicle.imageUrl"
                      style="
                        width: 180px;
                        margin-right: 15px;
                        border-radius: 15px;
                      "
                    />
                    <v-card class="smc-my-garage-card">
                      <v-card-title>
                        {{ data.item.name }}
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <v-list-item-content>
                              <v-list-item-subtitle style="text-align: left">
                                {{ data.item.vehicle.year }}
                              </v-list-item-subtitle>
                              <v-list-item-title style="text-align: left">
                                {{ data.item.vehicle.brand }}
                              </v-list-item-title>
                              <v-list-item-subtitle style="text-align: left">
                                {{ data.item.vehicle.range }}
                                <br />
                                {{ data.item.vehicle.model }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-col>
                          <v-col
                            v-if="
                              data.item.innovationPolicy &&
                              data.item.innovationPolicy.policyStatus !==
                                innovationPolicyStatuses.expired.value &&
                              data.item.innovationPolicy.policyStatus !==
                                innovationPolicyStatuses.cancelled.value
                            "
                            class="text-right"
                          >
                            <v-list-item-content>
                              <v-list-item-subtitle style="text-align: right">
                                {{ data.item.innovationPolicy.policyCode }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle style="text-align: right">
                                {{ data.item.innovationPolicy.vinNumber }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle style="text-align: right">
                                <strong>Start date:</strong>
                                {{
                                  dateHelper.formatDateShort(
                                    data.item.innovationPolicy.startDate
                                  )
                                }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle style="text-align: right">
                                <strong>End date:</strong>
                                {{
                                  dateHelper.formatDateShort(
                                    data.item.innovationPolicy.endDate
                                  )
                                }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle style="text-align: right">
                                <strong>End distance:</strong>
                                {{ data.item.innovationPolicy.endDistance }}
                                km
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-img
                              class="innovation-logo mb-2 float-right"
                              width="160"
                              contain
                              :src="require('@/assets/images/ig-logo.png')"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-combobox>
                <p
                  v-if="!showSearch"
                  class="mt-3 mb-0 text-center white--text subtitle-1"
                >
                  Add a new vehicle
                  <v-btn
                    color="primary"
                    class="text-none subtitle-1 px-2"
                    rounded
                    @click="showSearch = true"
                  >
                    Click here
                  </v-btn>
                </p>
              </v-col>
              <v-expand-transition>
                <v-col v-if="showSearch">
                  <p class="text-center title white--text font-weight-medium">
                    Let's start by searching for your vehicle
                  </p>
                  <v-combobox
                    id="searchVehicle-search"
                    class="smc-textfield-tall"
                    item-text="vehicleSummary"
                    item-value="uniqueId"
                    v-model="selectedVehicle"
                    append-icon=""
                    ref="searchVehicleSearch"
                    :items="vehicles"
                    :search-input.sync="searchTerm"
                    :menu-props="{
                      allowOverflow: true,
                      closeOnClick: false,
                      closeOnContentClick: false,
                      disableKeys: true,
                      openOnClick: false,
                      maxHeight: 500,
                    }"
                    chips
                    hide-details
                    return-object
                    no-filter
                    :clearable="$vuetify.breakpoint.smAndUp"
                    outlined
                    rounded
                    @change="
                      selectedGarageVehicle = undefined;
                      isPolicyQuote = false;
                      selectedVehicleChange();
                    "
                  >
                    <template
                      v-slot:prepend-inner
                      v-if="$vuetify.breakpoint.smAndUp"
                    >
                      <v-icon color="primary">mdi-magnify</v-icon>
                    </template>

                    <template v-slot:append v-if="$vuetify.breakpoint.smAndUp">
                      <v-progress-circular
                        v-if="searchIsLoading"
                        :size="20"
                        :width="2"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </template>

                    <template v-slot:no-data>
                      <v-list-item
                        v-if="
                          _.size(vehicles) == 0 &&
                          searchIsLoading == false &&
                          _.size(searchTerm) >= 2 &&
                          !manualVehicleEntry
                        "
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            Can't find your vehicle?
                            <br />
                            <br />
                            <v-btn
                              small
                              rounded
                              color="primary"
                              @click="startManualVehicleEntry()"
                            >
                              Manually enter details
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <template
                      v-slot:item="data"
                      v-bar="{
                        preventParentScroll: true,
                        scrollThrottle: 30,
                      }"
                    >
                      <v-list-item-avatar
                        size="100"
                        width="170"
                        tile
                        v-if="$vuetify.breakpoint.smAndUp"
                      >
                        <img
                          class="smc-search-image"
                          :src="data.item.imageUrl"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle style="text-align: left">
                          {{ data.item.year }}
                        </v-list-item-subtitle>
                        <v-list-item-title style="text-align: left">
                          {{ data.item.brand }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="text-align: left">
                          {{ data.item.range }}
                          <br />
                          {{ data.item.model }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-combobox>
                  <p class="mt-3 mb-0 text-center white--text subtitle-1">
                    Can't find your vehicle?
                    <v-btn
                      color="primary"
                      class="text-none subtitle-1 pb-1 px-2"
                      rounded
                      text
                      @click="startManualVehicleEntry()"
                    >
                      Click here
                    </v-btn>
                  </p>
                </v-col>
              </v-expand-transition>
            </v-row>
          </div>
        </v-expand-transition>
      </div>
    </v-card-text>

    <v-expand-transition>
      <div
        v-if="
          selectedVehicleShow ||
          manualVehicleEntry ||
          loadedVehicle ||
          (searchIsLoading && selectedVehicle)
        "
      >
        <v-card id="vehicle-card" ref="vehicleCard">
          <div v-if="searchIsLoading" class="pa-4">
            <vehicle-search-details-skeleton-loader />
          </div>
          <div
            v-if="manualVehicleEntry"
            class="flex-no-wrap justify-space-between"
          >
            <ValidationObserver ref="validationObserver">
              <v-form
                v-model="manualVehicleValid"
                lazy-validation
                ref="manualVehicleForm"
              >
                <div class="pt-1 mt-1"></div>
                <v-container class="pa-4">
                  <v-row>
                    <v-col cols="12" md="6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Vehicle manufacturer"
                        rules="required"
                      >
                        <v-text-field
                          id="manualVehicle-brand"
                          v-model="loadedVehicle.brand"
                          @keyup="sanitizeInput('brand')"
                          :error-messages="errors"
                          prepend-inner-icon="mdi-car"
                          label="Vehicle manufacturer"
                          hint="e.g.: Audi"
                          clearable
                          outlined
                          rounded
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Engine capacity"
                        rules="required"
                      >
                        <v-text-field
                          id="manualVehicle-capacity"
                          v-model="loadedVehicle.capacity"
                          @keyup="sanitizeInput('capacity')"
                          :error-messages="errors"
                          prepend-inner-icon="mdi-car"
                          label="Engine capacity"
                          clearable
                          outlined
                          rounded
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Vehicle range"
                        rules="required"
                      >
                        <v-text-field
                          id="manualVehicle-range"
                          v-model="loadedVehicle.range"
                          @keyup="sanitizeInput('range')"
                          :error-messages="errors"
                          prepend-inner-icon="mdi-car-back"
                          label="Vehicle range"
                          hint="e.g.: A4"
                          clearable
                          outlined
                          rounded
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Vehicle transmission type"
                        rules="required"
                      >
                        <v-select
                          id="manualVehicle-transmissionType"
                          v-model="loadedVehicle.transmissionType"
                          :error-messages="errors"
                          :items="['Manual', 'Automatic']"
                          prepend-inner-icon="mdi-car-shift-pattern"
                          label="Vehicle transmission type"
                          outlined
                          rounded
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Vehicle model"
                        rules="required"
                      >
                        <v-text-field
                          id="manualVehicle-model"
                          v-model="loadedVehicle.model"
                          @keyup="sanitizeInput('model')"
                          :error-messages="errors"
                          prepend-inner-icon="mdi-car-back"
                          label="Vehicle model"
                          hint="e.g.: 2.0 TFSI"
                          clearable
                          outlined
                          rounded
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Vehicle fuel type"
                        rules="required"
                      >
                        <v-select
                          id="manualVehicle-fuelType"
                          v-model="loadedVehicle.fuelType"
                          :error-messages="errors"
                          :items="['Petrol', 'Diesel', 'Hybrid', 'Electric']"
                          prepend-inner-icon="mdi-gas-station"
                          label="Vehicle fuel type"
                          outlined
                          rounded
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Vehicle year"
                        :rules="`required|numeric|min_value:1960|max_value:${currentYear}`"
                      >
                        <v-combobox
                          id="manualVehicle-year"
                          v-model="loadedVehicle.year"
                          :error-messages="errors"
                          :items="manualVehicleYearList"
                          prepend-inner-icon="mdi-car-2-plus"
                          label="Vehicle year"
                          hint="e.g.: 2015"
                          clearable
                          outlined
                          rounded
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Vehicle drive type"
                        rules="required"
                      >
                        <v-select
                          id="manualVehicle-driveType"
                          v-model="loadedVehicle.driveType"
                          :error-messages="errors"
                          :items="[
                            'Front wheel drive',
                            'Rear wheel drive',
                            'All wheel drive',
                            `I'm not sure`,
                          ]"
                          prepend-inner-icon="mdi-car-side"
                          label="Vehicle drive type"
                          outlined
                          rounded
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </ValidationObserver>
          </div>
          <div v-else class="d-flex flex-no-wrap justify-space-between">
            <v-row>
              <v-col
                cols="12"
                v-if="
                  this.$vuetify.breakpoint.smAndDown && loadedVehicle.imageUrl
                "
              >
                <v-avatar
                  v-if="!searchIsLoading"
                  class="ma-3"
                  size="125"
                  width="90%"
                  tile
                >
                  <img
                    :src="loadedVehicle.imageUrl"
                    class="smc-search-result-image"
                  />
                </v-avatar>
              </v-col>
              <v-col>
                <div v-if="!searchIsLoading">
                  <v-card-title
                    class="headline vehicle-card-title"
                    :class="$vuetify.breakpoint.mdAndUp ? 'text-left' : ''"
                  >
                    {{ loadedVehicle.year }}: {{ loadedVehicle.brand }} -
                    {{ loadedVehicle.range }}
                  </v-card-title>
                  <v-card-subtitle style="text-align: left">
                    <strong>{{ loadedVehicle.model }}</strong>
                    <br />

                    <span v-if="loadedVehicle.introductionDate">
                      <strong>Introduced in:</strong>
                      {{ introDate(loadedVehicle.introductionDate) }}
                      <br />
                    </span>

                    <span v-if="loadedVehicle.discontinuedDate">
                      <strong>Discontinued in:</strong>
                      {{ outroDate(loadedVehicle.discontinuedDate) }}
                      <br />
                    </span>

                    <span v-if="loadedVehicle.transmissionType">
                      <strong>Transmission:</strong>
                      {{ loadedVehicle.transmissionType }}
                      <br />
                    </span>

                    <span v-if="loadedVehicle.transmissionType">
                      <strong>Gearbox:</strong>
                      {{ loadedVehicle.gearboxType }}
                      <br />
                    </span>

                    <span v-if="loadedVehicle.fuelType">
                      <strong>Fuel type:</strong>
                      {{ loadedVehicle.fuelType }}
                      <br />
                    </span>

                    <span v-if="loadedVehicle.capacity">
                      <strong>Capacity:</strong>
                      {{ loadedVehicle.capacity }}
                      <br />
                    </span>

                    <span v-if="loadedVehicle.aspirationType">
                      <strong>Aspiration</strong>
                      {{ loadedVehicle.aspirationType }}
                      <br />
                    </span>

                    <span v-if="loadedVehicle.bodyType">
                      <strong>Body type:</strong>
                      {{ loadedVehicle.bodyType }}
                      <br />
                    </span>

                    <span v-if="loadedVehicle.driveType">
                      <strong>Drive:</strong>
                      {{ loadedVehicle.driveType }}
                      <br />
                    </span>

                    <span v-if="loadedVehicle.warranty">
                      <strong>Manufacturer warranty:</strong>
                      {{ loadedVehicle.warranty }}
                      <br />
                    </span>

                    <span v-if="loadedVehicle.serviceInterval1">
                      <strong>Service Interval 1:</strong>
                      {{ loadedVehicle.serviceInterval1 | numeral("0,0") }} KM
                      <br />
                    </span>

                    <span v-if="loadedVehicle.serviceInterval2">
                      <strong>Service Interval 2:</strong>
                      {{ loadedVehicle.serviceInterval2 | numeral("0,0") }} KM
                      <br />
                    </span>
                  </v-card-subtitle>
                  <v-card-actions v-if="!loadedVehicle.diskDriveReference">
                    <v-btn
                      x-small
                      outlined
                      rounded
                      right
                      absolute
                      color="midGrey"
                      @click="
                        manualVehicleEntry = true;
                        showQuote = false;
                      "
                    >
                      Edit
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-col>
              <v-col v-if="this.$vuetify.breakpoint.mdAndUp" class="text-right">
                <img
                  :src="loadedVehicle.imageUrl"
                  class="smc-search-result-image mt-3"
                  style="width: 300px; margin-right: 15px; border-radius: 8px"
                />
                <br />
                <v-card
                  class="smc-my-garage-card float-right mr-4"
                  width="300"
                  v-if="
                    selectedGarageVehicle &&
                    selectedGarageVehicle.innovationPolicy &&
                    selectedGarageVehicle.innovationPolicy.policyStatus !==
                      innovationPolicyStatuses.expired.value &&
                    selectedGarageVehicle.innovationPolicy.policyStatus !==
                      innovationPolicyStatuses.cancelled.value
                  "
                >
                  <v-card-title>
                    {{ selectedGarageVehicle.name }}
                  </v-card-title>
                  <v-card-text class="px-3 pb-3">
                    <v-list-item-content>
                      <v-list-item-subtitle style="text-align: left">
                        {{ selectedGarageVehicle.innovationPolicy.policyCode }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="text-align: left">
                        {{ selectedGarageVehicle.innovationPolicy.vinNumber }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="text-align: left">
                        <strong>Start date:</strong>
                        {{
                          dateHelper.formatDateShort(
                            selectedGarageVehicle.innovationPolicy.startDate
                          )
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="text-align: left">
                        <strong>End date:</strong>
                        {{
                          dateHelper.formatDateShort(
                            selectedGarageVehicle.innovationPolicy.endDate
                          )
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle style="text-align: left">
                        <strong>End distance:</strong>
                        {{
                          selectedGarageVehicle.innovationPolicy.endDistance
                            | numeral("0,0")
                        }}
                        km
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <router-link to="/#">
                      <v-img
                        class="innovation-logo"
                        width="160"
                        contain
                        :src="require('@/assets/images/ig-logo.png')"
                        v-ripple
                      />
                    </router-link>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <v-row v-if="dekraVehicleAttachment()">
            <v-col class="py-0">
              <pdf-viewer
                ref="dekra-pdf-viewer"
                :pdfUrlProp="attachmentUrl(dekraVehicleAttachment())"
                :pdfFilenameProp="
                  attachmentFileName(
                    dekraVehicleAttachment().attachmentFileName
                  )
                "
                :pdfAllPagesProp="true"
              >
              </pdf-viewer>
            </v-col>
          </v-row>
          <v-expand-transition>
            <v-row
              v-if="
                (selectedGarageVehicle &&
                  selectedGarageVehicle.serviceSchedule &&
                  selectedGarageVehicle.serviceSchedule.pastServices.length >
                    0) ||
                (selectedVehicle && isOrganization)
              "
            >
              <v-col class="text-right">
                <v-btn
                  small
                  rounded
                  outlined
                  right
                  color="info"
                  class="mb-2 mr-4"
                  @click="showServiceSchedule = !showServiceSchedule"
                >
                  {{ showServiceSchedule ? "Hide" : "Show" }}
                  Service Schedule
                </v-btn>
                <v-expand-transition>
                  <v-simple-table
                    v-if="!isOrganization && showServiceSchedule"
                    fixed-header
                    height="300px"
                  >
                    <thead>
                      <tr>
                        <th class="grey darken-3 white--text text-left">
                          Date
                        </th>
                        <th class="grey darken-3 white--text text-left">
                          Workshop
                        </th>
                        <th class="grey darken-3 white--text text-left">
                          Type
                        </th>
                        <th class="grey darken-3 white--text text-left">
                          Mileage
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in selectedGarageVehicle
                          .serviceSchedule.pastServices"
                        :key="index"
                        class="text-left"
                        :class="`border-${item.color}`"
                      >
                        <td>
                          {{ dateHelper.formatDateLong(item.serviceDate) }}
                        </td>
                        <td>
                          {{ item.workshopName }}
                        </td>
                        <td>
                          {{ item.type }}
                          <span>
                            <br />
                            <v-chip
                              v-if="item.trigger"
                              :color="item.color"
                              class="mb-1 ml-n3 pr-6"
                              small
                            >
                              {{ item.trigger }}
                            </v-chip>
                          </span>
                        </td>
                        <td>{{ item.vehicleMilleage }} KM</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <vehicle-details
                    v-if="isOrganization && showServiceSchedule"
                    :vehicleMappingProp="{ vehicle: selectedVehicle }"
                    :hideImageProp="true"
                  ></vehicle-details>
                </v-expand-transition>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-expand-transition>
            <v-card-text
              v-if="!showQuote && !searchIsLoading && warrantyWarningBrand()"
            >
              <div>
                <v-row>
                  <v-divider></v-divider>
                </v-row>
                <v-row>
                  <v-col>
                    <v-alert
                      border="left"
                      colored-border
                      type="error"
                      elevation="2"
                      class="mb-0 body-2 text--secondary"
                    >
                      <div>
                        Disclaimer - The onus is on you to confirm that repairs
                        are carried out by an accredited repairer in order to
                        prevent any conflict with the terms as set out by your
                        vehicle manufacturer
                      </div>
                      <v-divider class="my-4 danger"></v-divider>
                      <v-row no-gutters>
                        <v-col class="text-center">
                          <v-checkbox
                            v-model="acceptWarranty"
                            class="ma-0 d-inline-flex"
                            label="I Understand"
                            hide-details
                          >
                          </v-checkbox>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-expand-transition>
          <v-expand-transition>
            <v-card-text v-if="!showQuote && !searchIsLoading">
              <div>
                <v-row>
                  <v-divider></v-divider>
                </v-row>
                <v-row v-if="isOrganization">
                  <v-col>
                    <div class="flex" style="height: 100%">
                      <v-card
                        class="smc-my-garage-card"
                        height="100%"
                        @click="checkServiceType('inspection')"
                        :outlined="inspectionChecked"
                        :class="inspectionChecked ? 'service-type-checked' : ''"
                      >
                        <v-card-title>
                          <v-checkbox
                            v-model="inspectionChecked"
                            color="white"
                            label=""
                            class="my-2"
                            hide-details
                          >
                            <template v-slot:label>
                              <span class="white--text">
                                Select a Workshop to Quote After Inspection
                              </span>
                            </template>
                          </v-checkbox>
                        </v-card-title>
                        <v-card-text>
                          <v-list dense>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>
                                  mdi-clipboard-text-search-outline
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                Send request for physical inspection
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-car-wrench</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                Workshop is notified & quote after inspection
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-engine-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                Ability to track vehicle status
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="flex" style="height: 100%">
                      <v-card
                        class="smc-my-garage-card"
                        height="100%"
                        @click="checkServiceType('repair')"
                        :outlined="repairChecked"
                        :class="repairChecked ? 'service-type-checked' : ''"
                      >
                        <v-card-title>
                          <v-checkbox
                            v-model="repairChecked"
                            color="white"
                            label=""
                            class="my-2"
                            hide-details
                          >
                            <template v-slot:label>
                              <span class="white--text">
                                Get Multiple Quotes
                              </span>
                            </template>
                          </v-checkbox>
                        </v-card-title>
                        <v-card-text>
                          <v-list dense>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>
                                  mdi-clipboard-text-search-outline
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                Get valid quotes from one or more workshops
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-car-wrench</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                Applicable to Services & Routine Repairs
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-engine-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                Please provide clear details for an accurate
                                quote
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col>
                    <div class="flex" style="height: 100%">
                      <v-card
                        class="smc-my-garage-card"
                        height="100%"
                        @click="checkServiceType('service')"
                        :outlined="serviceChecked"
                        :class="
                          policyChecked || serviceChecked
                            ? 'service-type-checked'
                            : ''
                        "
                      >
                        <v-card-title>
                          <v-checkbox
                            v-if="isPolicyQuote"
                            v-model="policyChecked"
                            color="white"
                            class="my-2"
                            hide-details
                          >
                            <template v-slot:label>
                              <span class="white--text">
                                I need a service
                              </span>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-else
                            v-model="serviceChecked"
                            color="white"
                            label=""
                            class="my-2"
                            hide-details
                          >
                            <template v-slot:label>
                              <span class="white--text">
                                I need a service
                              </span>
                            </template>
                          </v-checkbox>
                        </v-card-title>
                        <v-card-text>
                          <v-list dense>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-alert-circle-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                Service due light on
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-car-info</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                Vehicle checkup
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-clipboard-list-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                Annual maintenance service
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider
                              v-if="
                                selectedGarageVehicle &&
                                selectedGarageVehicle.serviceSchedule &&
                                selectedGarageVehicle.serviceSchedule
                                  .pastServices.length > 0 &&
                                !selectedGarageVehicle.serviceSchedule
                                  .promptService
                              "
                            >
                            </v-divider>
                            <v-list-item
                              v-if="
                                selectedGarageVehicle &&
                                selectedGarageVehicle.serviceSchedule &&
                                selectedGarageVehicle.serviceSchedule
                                  .pastServices.length > 0 &&
                                !selectedGarageVehicle.serviceSchedule
                                  .promptService
                              "
                            >
                              <v-list-item-icon>
                                <v-icon color="red">mdi-alert</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="text-left">
                                Please note - your next service might not be due
                                yet.
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="flex" style="height: 100%">
                      <v-card
                        class="smc-my-garage-card"
                        height="100%"
                        @click="checkServiceType('repair')"
                        :outlined="repairChecked"
                        :class="repairChecked ? 'service-type-checked' : ''"
                      >
                        <v-card-title>
                          <v-checkbox
                            v-model="repairChecked"
                            color="white"
                            label=""
                            class="my-2"
                            hide-details
                          >
                            <template v-slot:label>
                              <span class="white--text"> I need a repair </span>
                            </template>
                          </v-checkbox>
                        </v-card-title>
                        <v-card-text>
                          <v-list dense>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>
                                  mdi-clipboard-text-search-outline
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                Detailed job request
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-car-wrench</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                Repairer knowledge
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-engine-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                Parts replacement/repair
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider v-if="repairChecked"> </v-divider>
                            <v-list-item v-if="repairChecked">
                              <v-list-item-icon>
                                <v-icon color="info">mdi-alert</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="text-left">
                                Kindly note that repair requests may take longer
                                than service requests due to parts pricing
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider v-if="isPolicyQuote"> </v-divider>
                            <v-list-item v-if="isPolicyQuote">
                              <v-list-item-icon>
                                <v-icon color="red">mdi-alert</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="text-left">
                                Please note - your policy might not cover all
                                types of repairs
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider></v-divider>
                </v-row>
                <v-row>
                  <v-col>
                    <p>Please choose an option above.</p>
                    <v-btn
                      :disabled="
                        (!policyChecked &&
                          !serviceChecked &&
                          !repairChecked &&
                          !inspectionChecked) ||
                        (warrantyWarningBrand() && !acceptWarranty) ||
                        (manualVehicleEntry && !manualVehicleValid)
                      "
                      color="primary"
                      rounded
                      @click="startQuote"
                    >
                      Start
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-expand-transition>
          <v-expand-transition>
            <v-card-text v-if="showQuote">
              <v-btn
                color="primary"
                small
                rounded
                @click="
                  resetQuoteRequestForm({
                    resetQuoteRequest: true,
                    skipConfirmation: false,
                  })
                "
              >
                Clear
              </v-btn>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </div>
    </v-expand-transition>

    <v-card-text class="px-0">
      <v-expand-transition>
        <div v-if="showQuote">
          <quote-request-create
            :vehicleProp="loadedVehicle"
            :quoteRequestProp="quoteRequest"
            :quoteTypeProp="quoteType"
            @resetForm="resetQuoteRequestForm($event)"
            @openDekra="openDekra"
          ></quote-request-create>
        </div>
      </v-expand-transition>
    </v-card-text>

    <v-dialog
      v-if="selectedGarageVehicle && selectedGarageVehicle.innovationPolicy"
      v-model="innovationPolicyWarningDialogShow"
      max-width="400"
      transition="dialog-top-transition"
    >
      <v-card class="pa-0">
        <v-toolbar color="primary" dark height="48" class="mb-6">
          {{ innovationPolicyWarning.title }}
        </v-toolbar>
        <v-card-text>
          <v-card
            color="orange"
            style="
              position: absolute;
              left: 0;
              right: 0;
              top: 48px;
              height: 110px;
            "
          >
            <v-card-text style="color: white" class="pb-0">
              <v-icon x-large style="color: white">mdi-alert-circle</v-icon>
            </v-card-text>
            <v-card-text style="color: white" class="pt-0">
              <p style="padding-top: 15px" class="mb-0">Please note</p>
            </v-card-text>
          </v-card>
          <div style="margin-top: 134px">
            <v-row>
              <v-col>
                <p class="mb-0">{{ innovationPolicyWarning.message }}</p>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            class="smc-text-button smc-small-button"
            color="primary"
            @click="innovationPolicyWarningDialogShow = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="resetFormDialogShow"
      transition="dialog-top-transition"
      max-width="400"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark> Clear Form </v-toolbar>
          <v-card-text class="pb-0">
            <div class="text pa-6">
              Are you sure you want to discard your progress and clear the form?
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              @click="dialog.value = false"
              rounded
              color="secondary"
              class="smc-text-button smc-small-button mx-1 my-1"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="resetQuoteRequestFormConfirm"
              rounded
              color="primary"
              class="smc-text-button smc-small-button mx-1 my-1"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import Typed from "typed.js";
import roller from "vue-roller";
import validate from "@/mixins/validator";
import { vehicleService, quoteRequestService, userService } from "@/services";
import {
  constants,
  vehicleHelper,
  quoteRequestHelper,
  dateHelper,
  innovationPolicyStatuses,
} from "@/helpers";
import quoteRequestCreate from "@/components/quote-request-create";
import vehicleDetails from "./vehicle-details";
import vehicleSearchDetailsSkeletonLoader from "@/components/widgets/vehicle-search-details-skeleton-loader";
import pdfViewer from "@/components/widgets/pdf-viewer";

export default {
  name: "vehicle-search",
  mixins: [validate],
  props: {
    vehicleMappingIdProp: String,
    vehicleModel: Object,
    garageVehicleProp: Object,
    quoteRequestModel: Object,
    showExpandedProp: Boolean,
    noSearchProp: Boolean,
    isPolicyQuoteProp: Boolean,
  },
  components: {
    quoteRequestCreate,
    vehicleDetails,
    vehicleSearchDetailsSkeletonLoader,
    roller,
    pdfViewer,
  },
  data() {
    return {
      dateHelper,
      innovationPolicyStatuses,

      searchInitialized: false,
      showSearch: false,
      showGarage: false,
      showServiceSchedule: false,
      resetFormDialogShow: false,

      policyChecked: false,
      serviceChecked: false,
      repairChecked: false,
      inspectionChecked: false,

      flipped: true,
      rollerCounter: "0",
      searchTerm: null,
      searchIsLoading: false,
      manualVehicleEntry: false,
      manualVehicleValid: true,
      timeout: 0,
      vehicles: [],
      showQuote: this.quoteRequestModel !== undefined,

      noSearch: this.noSearchProp,
      searchLoading: false,
      isPolicyQuote: this.isPolicyQuoteProp,
      acceptWarranty: false,

      selectedVehicleShow: false,
      selectedVehicle: this.vehicleModel,
      selectedGarageVehicle: this.garageVehicleProp,
      loadedVehicle: this.vehicleModel,
      quoteRequest: this.quoteRequestModel,
      quoteType: "",

      policyRepairQuoteWarningShow: false,
      myGarageVehicles: [],
      innovationPolicyWarningDialogShow: false,
      innovationPolicyWarning: {
        level: "",
        title: "",
        message: "",
      },
    };
  },
  computed: {
    currentYear() {
      return moment().year();
    },
    manualVehicleYearList() {
      var beginningYear = 1960;
      var yearsList = [];
      for (var i = this.currentYear; i >= beginningYear; i--) {
        yearsList.push(i);
      }
      return yearsList;
    },
    isOrganization: function () {
      return this.$store.getters["user/isOrganization"];
    },
  },
  async created() {
    if (this.showExpandedProp) {
      this.updateSearchInitialized(true);
    }
    if (this.garageVehicleProp) {
      this.selectedGarageVehicle = _.cloneDeep(this.garageVehicleProp);
      await this.selectedGarageVehicleChange();
    }
    setInterval(() => {
      this.flip();
    }, 6500);

    var quoteRequest =
      this.$store.getters["quoteRequestCreate/getQuoteRequest"];

    //if (this.isPolicyQuote) {
    //  this.startPolicyQuote();
    //} else
    if (quoteRequest != undefined) {
      this.quoteRequest = quoteRequest;
      this.loadedVehicle =
        this.$store.getters["quoteRequestCreate/getQuoteRequestVehicle"];
      this.selectedGarageVehicle =
        this.$store.getters["quoteRequestCreate/getGarageVehicle"];
      this.quoteType = this.$store.getters["quoteRequestCreate/getQuoteType"];
      this.showQuote = true;
      this.updateSearchInitialized(true);
      this.noSearch = true;

      if (this.quoteType == constants.policyLabel) {
        this.isPolicyQuote = true;
      }
    }
  },
  async mounted() {
    var cookieValue = this.$cookies.get("customers_count");
    if (cookieValue) {
      this.rollerCounter = cookieValue;
    } else {
      var result = await quoteRequestService.getCustomersCount();
      if (result.status == 200) {
        this.rollerCounter = `${result.data}`;
        this.$cookies.set("customers_count", result.data, "1d");
      }
    }
  },
  watch: {
    searchTerm: async function (val) {
      if (val) {
        if (val.length >= 2) {
          this.searchIsLoading = true;
          try {
            this.vehicles = await vehicleService.searchVehicles(val);
            this.searchIsLoading = false;
          } catch (error) {
            if (error.__CANCEL__) {
              // do essentially nothing
            }
          }
        } else {
          this.vehicles = undefined;
        }
      }
    },
    selectedVehicle: async function (val) {
      if (val && typeof val == "object") {
        this.manualVehicleEntry = false;
        if (process.env.VUE_APP_BYPASS_DISKDRIVE !== "true") {
          if (val.diskDriveReference) {
            try {
              this.searchIsLoading = true;
              this.loadedVehicle = await vehicleService.vehicleLookup(
                val.diskDriveReference
              );
              this.loadedVehicle.year = val.year;
              this.searchIsLoading = false;
            } catch (error) {
              if (error) {
                // DiskDrive lookup failed. I can at least populate the vehicle with everthing pulled from lucene.
                this.loadedVehicle = val;
                this.searchIsLoading = false;
              }
            }
          }
        } else {
          // Bypassing diskdrive means loading the vehicle as returned by lucene.
          this.loadedVehicle = val;
        }

        this.$emit("vehicleSelected");
      } else if (val == undefined) {
        // We end up here if the user clears the search box.
        this.resetQuoteRequestForm({
          resetQuoteRequest: true,
          skipConfirmation: true,
        });
        this.$emit("searchCleared");
      }

      this.policyChecked = false;
      this.serviceChecked = false;
      this.repairChecked = false;
      this.inspectionChecked = false;

      // if (this.loadedVehicle && this.isOrganization) {
      //   this.repairChecked = true;
      //   this.startQuote();
      // }
    },
    selectedGarageVehicle: async function (val) {
      if (val) {
        this.selectedVehicle = val.vehicle;
        if (val.innovationPolicy) {
          let status = val.innovationPolicy.policyStatus;
          switch (status) {
            case innovationPolicyStatuses.active.value:
              this.isPolicyQuote = true;
              break;
            case innovationPolicyStatuses.suspened.value:
              this.innovationPolicyWarning = innovationPolicyStatuses.suspened;
              this.innovationPolicyWarningDialogShow = true;
              this.isPolicyQuote = true;
              break;
            case innovationPolicyStatuses.inactive.value:
              this.innovationPolicyWarning = innovationPolicyStatuses.inactive;
              this.innovationPolicyWarningDialogShow = true;
              this.isPolicyQuote = true;
              break;
            case innovationPolicyStatuses.hold.value:
              this.innovationPolicyWarning = innovationPolicyStatuses.hold;
              this.innovationPolicyWarningDialogShow = true;
              this.isPolicyQuote = true;
              break;
            case innovationPolicyStatuses.cancellationProgress.value:
              this.innovationPolicyWarning =
                innovationPolicyStatuses.cancellationProgress;
              this.innovationPolicyWarningDialogShow = true;
              this.isPolicyQuote = true;
              break;
            case innovationPolicyStatuses.cancellationPendingRecovery.value:
              this.innovationPolicyWarning =
                innovationPolicyStatuses.cancellationPendingRecovery;
              this.innovationPolicyWarningDialogShow = true;
              this.isPolicyQuote = true;
              break;
            case innovationPolicyStatuses.cancellationPendingCollections.value:
              this.innovationPolicyWarning =
                innovationPolicyStatuses.cancellationPendingCollections;
              this.innovationPolicyWarningDialogShow = true;
              this.isPolicyQuote = true;
              break;
            case innovationPolicyStatuses.cancellationRequested.value:
              this.innovationPolicyWarning =
                innovationPolicyStatuses.cancellationRequested;
              this.innovationPolicyWarningDialogShow = true;
              this.isPolicyQuote = true;
              break;
            default:
              this.isPolicyQuote = false;
              break;
          }
        } else {
          this.isPolicyQuote = false;
        }
      } else {
        this.innovationPolicyWarning = {
          level: "",
          title: "",
          message: "",
        };
      }
    },
    showSearch: async function (val) {
      if (val) {
        setTimeout(() => {
          new Typed("#searchVehicle-search", {
            strings: process.env.VUE_APP_SEARCH_PLACEHOLDER.split(";"),
            attr: "placeholder",
            typeSpeed: 40,
            backSpeed: 10,
            backDelay: 3500,
            loop: true,
          });
        }, 500);
      }
    },
  },
  methods: {
    flip() {
      this.flipped = !this.flipped;
    },
    warrantyWarningBrand() {
      if (this.manualVehicleEntry) {
        return true;
      }
      if (this.selectedVehicle) {
        var warningBrands =
          process.env.VUE_APP_WARRANTY_WARNING_BRANDS.split(",");
        var index = warningBrands.indexOf(this.selectedVehicle.brand);
        return index !== -1;
      }
      return false;
    },
    async updateSearchInitialized(val) {
      var vm = this;
      if (val) {
        if (this.$store.getters["user/isAuthenticated"]) {
          this.searchLoading = true;
          var result = await userService.getVehicles({
            filter: "",
            page: 1,
            itemsPerPage: 50,
          });

          this.myGarageVehicles = _.orderBy(
            result.data.items,
            ["dateCreatedUtc"],
            ["desc"]
          );
          this.myGarageVehicles.forEach((mapping) => {
            if (!mapping.name) {
              mapping.name = "VEHICLE";
            }
          });

          if (this.vehicleMappingIdProp) {
            var selectedMapping = _.find(this.myGarageVehicles, function (v) {
              return v.id == vm.vehicleMappingIdProp;
            });
            if (selectedMapping) {
              this.selectedGarageVehicle = selectedMapping;
              this.noSearch = true;
              await this.selectedGarageVehicleChange();
            }
          }

          if (this.myGarageVehicles.length > 0) {
            this.showGarage = true;
            this.showSearch = false;
          } else {
            this.showSearch = true;
          }

          this.searchLoading = false;
        } else {
          this.showSearch = true;
        }

        this.searchInitialized = val;
      }
    },
    searchChange() {
      // In here is where I'm thinking of doing the search box enter function.
    },
    introDate(date) {
      return moment(date).format("MMMM, YYYY");
    },
    outroDate(date) {
      if (date) {
        return moment(date).format("MMMM, YYYY");
      } else {
        return "Current";
      }
    },
    startManualVehicleEntry() {
      this.selectedGarageVehicle = undefined;
      this.showQuote = false;
      this.isPolicyQuote = false;
      this.$nextTick(function () {
        this.loadedVehicle = vehicleHelper.getEmptyVehicle();
        this.loadedVehicle.imageUrl = require("@/assets/images/no-entry-for-motor-vehicle.png");
        this.manualVehicleEntry = true;
      });
    },
    sanitizeInput(fieldName) {
      // Remove single quote (') character from the input value
      const sanitizedValue = this.loadedVehicle[fieldName].replace(/'/g, "");

      // Assign the sanitized value back to the corresponding field in selectedVehicle
      this.loadedVehicle[fieldName] = sanitizedValue;
    },
    getEmptyQuoteRequest() {
      let quoteRequest = quoteRequestHelper.getEmptyQuoteRequest();

      if (this.selectedGarageVehicle) {
        quoteRequest.existingVehicleMileage =
          this.selectedGarageVehicle.vehicleMileage;
        quoteRequest.vehicleName = this.selectedGarageVehicle.name;
        quoteRequest.vehicleVinNumber =
          this.selectedGarageVehicle.vehicleVinNumber;
        quoteRequest.vehicleEngineNumber =
          this.selectedGarageVehicle.vehicleEngineNumber;
        quoteRequest.vehicleLicensePlate =
          this.selectedGarageVehicle.vehicleLicensePlate;
        quoteRequest.vehicleColour = this.selectedGarageVehicle.vehicleColour;
      }

      return quoteRequest;
    },
    checkServiceType(type) {
      this.policyChecked = false;
      this.serviceChecked = false;
      this.repairChecked = false;
      this.inspectionChecked = false;

      if (type == "service") {
        if (this.isPolicyQuote) {
          this.policyChecked = true;
        } else {
          this.serviceChecked = true;
        }
      } else if (type == "repair") {
        this.repairChecked = true;
      } else if (type == "inspection") {
        this.inspectionChecked = true;
      }
    },

    dekraVehicleAttachment() {
      let attachment = undefined;
      if (
        this.selectedGarageVehicle &&
        this.selectedGarageVehicle.vehicle &&
        this.selectedGarageVehicle.vehicle.vehicleAttachments &&
        this.selectedGarageVehicle.vehicle.vehicleAttachments.length > 0
      ) {
        attachment = this.selectedGarageVehicle.vehicle.vehicleAttachments.find(
          (att) => /.*dekra.*\.pdf$/i.test(att.attachmentFileName)
        );
      }
      return attachment;
    },
    attachmentFileName(fileName) {
      if (fileName.length <= 20) {
        return fileName;
      } else {
        const extensionIndex = fileName.lastIndexOf(".");
        if (extensionIndex > 0) {
          const basename = fileName.substring(0, extensionIndex);
          const extension = fileName.substring(extensionIndex);
          const truncatedName =
            basename.substring(0, 18 - extension.length) + "..";
          return truncatedName + extension;
        } else {
          // If there's no file extension, simply truncate to 20 characters
          return fileName.substring(0, 18) + "..";
        }
      }
    },
    attachmentUrl(attachment) {
      if (!attachment) {
        return "";
      }
      let contentUrl = process.env.VUE_APP_SMC_CONTENT_URL;
      let fileName = this.attachmentFileName(attachment.attachmentFileName);
      let filePath = `vehicles/${this.selectedGarageVehicle.vehicleId}/VehicleReport`;
      return `${contentUrl}/${filePath}/${fileName}`;
    },
    openDekra() {
      if (this.$refs["dekra-pdf-viewer"]) {
        if (!this.$refs["dekra-pdf-viewer"].getIsInitialized()) {
          this.$refs["dekra-pdf-viewer"].openPage(1);
          setTimeout(() => {
              this.$refs["dekra-pdf-viewer"].openPage(3);
          }, 1000);
        }
        else {
          this.$refs["dekra-pdf-viewer"].openPage(3);
        }
      }
    },

    async startQuote() {
      if (this.policyChecked) {
        await this.startPolicyQuote();
      } else if (this.serviceChecked) {
        await this.startServiceQuote();
      } else if (this.repairChecked) {
        await this.startRepairQuote();
      } else if (this.inspectionChecked) {
        await this.startInspectionQuote();
      }
    },
    async startPolicyQuote() {
      let quoteRequest = this.getEmptyQuoteRequest();
      quoteRequest.isPolicyQuote = true;
      this.$store.dispatch("quoteRequestCreate/setQuoteRequest", quoteRequest);
      this.$store.dispatch(
        "quoteRequestCreate/setQuoteRequestVehicle",
        this.loadedVehicle
      );
      this.$store.dispatch(
        "quoteRequestCreate/setGarageVehicle",
        this.selectedGarageVehicle
      );

      this.showSearch = false;
      this.showGarage = false;
      this.manualVehicleEntry = false;
      this.quoteType = constants.policyLabel;
      this.showQuote = true;
    },
    async startServiceQuote() {
      if (await this.valdiateForm()) {
        if (
          this.$store.getters["quoteRequestCreate/getQuoteRequest"] == undefined
        ) {
          this.$store.dispatch(
            "quoteRequestCreate/setQuoteRequest",
            this.getEmptyQuoteRequest()
          );
          this.$store.dispatch(
            "quoteRequestCreate/setQuoteRequestVehicle",
            this.loadedVehicle
          );
          this.$store.dispatch(
            "quoteRequestCreate/setGarageVehicle",
            this.selectedGarageVehicle
          );
        }

        this.showSearch = false;
        this.showGarage = false;

        this.manualVehicleEntry = false;
        //this.quoteType = constants.serviceLabel;
        this.quoteType = constants.repairLabel;
        this.showQuote = true;
      }
    },
    async startRepairQuote() {
      if (await this.valdiateForm()) {
        if (
          this.$store.getters["quoteRequestCreate/getQuoteRequest"] == undefined
        ) {
          this.$store.dispatch(
            "quoteRequestCreate/setQuoteRequest",
            this.getEmptyQuoteRequest()
          );
          this.$store.dispatch(
            "quoteRequestCreate/setQuoteRequestVehicle",
            this.loadedVehicle
          );
          this.$store.dispatch(
            "quoteRequestCreate/setGarageVehicle",
            this.selectedGarageVehicle
          );
        }

        this.showSearch = false;
        this.showGarage = false;

        this.manualVehicleEntry = false;
        if (this.isOrganization) {
          this.quoteType = constants.organizationLabel;
        } else {
          this.quoteType = constants.repairLabel;
        }
        this.showQuote = true;
      }
    },
    async startInspectionQuote() {
      if (await this.valdiateForm()) {
        if (
          this.$store.getters["quoteRequestCreate/getQuoteRequest"] == undefined
        ) {
          this.$store.dispatch(
            "quoteRequestCreate/setQuoteRequest",
            this.getEmptyQuoteRequest()
          );
          this.$store.dispatch(
            "quoteRequestCreate/setQuoteRequestVehicle",
            this.loadedVehicle
          );
          this.$store.dispatch(
            "quoteRequestCreate/setGarageVehicle",
            this.selectedGarageVehicle
          );
        }
      }

      this.showSearch = false;
      this.showGarage = false;

      this.manualVehicleEntry = false;
      this.quoteType = constants.inspectionLabel;
      this.showQuote = true;
    },
    async valdiateForm() {
      if (!this.manualVehicleEntry) {
        return true;
      }
      return await this.$refs.validationObserver.validate();
    },
    resetQuoteRequestForm(attr) {
      if (attr.resetQuoteRequest) {
        if (attr.skipConfirmation) {
          this.resetQuoteRequestFormConfirm();
        } else {
          this.resetFormDialogShow = true;
        }
      } else {
        this.showQuote = false;
        this.$vuetify.goTo(0);
        setTimeout(() => {
          // Slight delay, then scroll to top again, to account for any page amimations
          this.$vuetify.goTo(0);
        }, 500);

        this.$emit("searchCleared");
      }
    },
    resetQuoteRequestFormConfirm() {
      this.showQuote = false;

      this.$store.dispatch("quoteRequestCreate/resetQuoteRequest");
      this.selectedVehicleShow = false;

      this.loadedVehicle = undefined;
      this.manualVehicleEntry = false;
      this.acceptWarranty = false;
      this.searchTerm = null;
      this.vehicles = [];
      this.selectedVehicle = undefined;
      this.isPolicyQuote = false;

      this.policyChecked = false;
      this.serviceChecked = false;
      this.repairChecked = false;
      this.inspectionChecked = false;

      this.noSearch = false;
      this.searchInitialized = true;
      if (this.myGarageVehicles && this.myGarageVehicles.length > 0) {
        this.showGarage = true;
      } else {
        this.showSearch = true;
      }

      this.selectedGarageVehicle = undefined;
      this.resetFormDialogShow = false;

      this.$vuetify.goTo(0);
      setTimeout(() => {
        // Slight delay, then scroll to top again, to account for any page amimations
        this.$vuetify.goTo(0);
        this.resetFormDialogShow = false;
      }, 500);

      this.$emit("searchCleared");
    },
    selectedVehicleChange() {
      if (this.selectedVehicle) {
        this.selectedVehicleShow = true;
      } else {
        this.selectedVehicleShow = false;
      }
    },
    async selectedGarageVehicleChange() {
      this.showServiceSchedule = false;
      if (this.selectedGarageVehicle) {
        this.selectedVehicleShow = true;
        if (
          !this.selectedGarageVehicle.serviceSchedule &&
          this.selectedGarageVehicle.customerVehicleMappingMileageHistories &&
          this.selectedGarageVehicle.customerVehicleMappingMileageHistories
            .length > 0 &&
          !this.isOrganization
        ) {
          let result = await userService.queryVehicleServiceSchedule(
            this.selectedGarageVehicle.id
          );
          if (result.status == 200) {
            this.$nextTick(() => {
              this.selectedGarageVehicle.serviceSchedule = result.data;
            });
          }
        }
      } else {
        this.selectedVehicleShow = false;
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/vuetify-elements/card.scss";

#flip-container {
  cursor: pointer;
}

#flip-container .expand-transition-enter-active,
#flip-container .expand-transition-leave-active {
  transition-duration: 1.2s !important;
}

.smc-my-garage-card {
  width: 100%;
  /*max-width: 660px;*/
  margin-bottom: 15px;
  border-radius: 8px !important;
}

.smc-my-garage-card .v-card__title {
  background-color: #ed3237;
  color: #fff;
  font-size: 15px;
  padding: 2px 15px;
}

.smc-my-garage-card .v-card__text {
  padding-left: 5px;
  padding-bottom: 0;
}

.smc-my-garage-card .v-list-item__title {
  font-size: 15px;
}

.smc-my-garage-card .v-list-item__subtitle {
  font-size: 13px;
}

.smc-my-garage-list-item {
  padding: 0;
  padding-bottom: 8px;
  margin: 12px 0;
  border-left: 1px solid #ed3237;
  border-right: 1px solid #ed3237;
  border-bottom: 1px solid #ed3237;
  border-radius: 8px;
}

.smc-my-garage-list-item > .smc-my-garage-list-item-title {
  background-color: #ed3237;
  color: #fff;
  padding: 2px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.smc-my-garage-list-item .v-list-item__title,
.smc-my-garage-list-item .v-list-item__subtitle {
  padding: 0 12px;
}

.service-type-checked {
  border: 1px solid #ed3237;
}

.service-type-checked .v-list-item__content {
  font-weight: 500;
}
</style>

<style>
@import "../assets/scss/components/vehicle-search.scss";

.roller {
  display: inline;
}

.roller .rollerBlock {
  padding: 0;
}

tr.border-info td {
  border-top: 1px solid #2196f3 !important;
  border-bottom: 1px solid #2196f3 !important;
  color: #2196f3;
}

tr.border-error td {
  border-top: 1px solid #ed3237 !important;
  border-bottom: 1px solid #ed3237 !important;
  color: #ed3237;
}
</style>
