<template>
  <v-simple-table class="vehicle-details-table" dense>
    <template v-slot:default>
      <thead v-if="!hideImageProp && vehicleMappingProp.vehicle.imageUrl">
        <tr>
          <th colspan="4" class="text-center">
            <v-img :src="vehicleMappingProp.vehicle.imageUrl" :lazy-img="vehicleMappingProp.vehicle.imageUrl"
              width="320" class="mx-auto"></v-img>
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th colspan="4" class="vehicle-details-table-heading">General Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Year</th>
          <td>{{ vehicleMappingProp.vehicle.year }}</td>
          <th>Brand</th>
          <td>{{ vehicleMappingProp.vehicle.brand }}</td>
        </tr>
        <tr>
          <th>Range</th>
          <td>{{ vehicleMappingProp.vehicle.range }}</td>
          <th>Model</th>
          <td>{{ vehicleMappingProp.vehicle.model }}</td>
        </tr>
        <tr>
          <th>Body Type</th>
          <td>{{ vehicleMappingProp.vehicle.bodyType }}</td>
          <th>Mead Code</th>
          <td>{{ vehicleMappingProp.vehicle.meadCode }}</td>
        </tr>
        <tr>
          <th>Introduction Date</th>
          <td>
            <span v-if="vehicleMappingProp.vehicle.introductionDate">
              {{ dateHelper.formatDateShort(vehicleMappingProp.vehicle.introductionDate) }}
            </span>
          </td>
          <th>Discontinued Date</th>
          <td>
            <span v-if="vehicleMappingProp.vehicle.discontinuedDate">
              {{ dateHelper.formatDateShort(vehicleMappingProp.vehicle.discontinuedDate) }}
            </span>
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colspan="4" class="vehicle-details-table-heading">Drivetrain Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Aspiration</th>
          <td>{{ vehicleMappingProp.vehicle.aspirationType }}</td>
          <th>Fuel Type</th>
          <td>{{ vehicleMappingProp.vehicle.fuelType }}</td>
        </tr>
        <tr>
          <th>Capacity</th>
          <td>{{ vehicleMappingProp.vehicle.capacity }}</td>
          <th>Cylinders</th>
          <td>{{ vehicleMappingProp.vehicle.cylinders }}</td>
        </tr>
        <tr>
          <th>Transmission</th>
          <td>{{ vehicleMappingProp.vehicle.transmissionType }}</td>
          <th>Gearbox</th>
          <td>{{ vehicleMappingProp.vehicle.gearboxType }}</td>
        </tr>
        <tr>
          <th>Drive Type</th>
          <td>{{ vehicleMappingProp.vehicle.driveType }}</td>
          <th>Wheels Driven</th>
          <td>{{ vehicleMappingProp.vehicle.wheelsDriven }}</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colspan="4" class="vehicle-details-table-heading">Brake Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>ABS</th>
          <td>{{ vehicleMappingProp.vehicle.abs }}</td>
          <th>Ceramics</th>
          <td>{{ vehicleMappingProp.vehicle.ceramics }}</td>
        </tr>
        <tr>
          <th>Front Brake Type 1</th>
          <td>{{ vehicleMappingProp.vehicle.frontBrakeType1 }}</td>
          <th>Front Brake Type 2</th>
          <td>{{ vehicleMappingProp.vehicle.frontBrakeType2 }}</td>
        </tr>
        <tr>
          <th>Rear Brake Type</th>
          <td colspan="3">{{ vehicleMappingProp.vehicle.rearBrakeType }}</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colspan="4" class="vehicle-details-table-heading">Suspension Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Front Suspension Type</th>
          <td>{{ vehicleMappingProp.vehicle.suspensionFrontType }} </td>
          <th>Rear Suspension Type</th>
          <td>{{ vehicleMappingProp.vehicle.suspensionRearType }}</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colspan="4" class="vehicle-details-table-heading">Warranty/Service Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Plan Type</th>
          <td>{{ vehicleMappingProp.vehicle.planType }} </td>
          <th>Maintenance Exclusions</th>
          <td>{{ vehicleMappingProp.vehicle.maintenanceExclusions }}</td>
        </tr>
        <tr>
          <th>Service Interval 1</th>
          <td>{{ vehicleMappingProp.vehicle.serviceInterval1 }} </td>
          <th>Service Interval 2</th>
          <td>{{ vehicleMappingProp.vehicle.serviceInterval2 }}</td>
        </tr>
        <tr>
          <th>Maintenance Mileage Interval</th>
          <td>{{ vehicleMappingProp.vehicle.maintenanceMileageInterval }} </td>
          <th>Maintenance Time Interval</th>
          <td>{{ vehicleMappingProp.vehicle.maintenanceTimeInterval }}</td>
        </tr>
        <tr>
          <th>Warranty Mileage Interval</th>
          <td>{{ vehicleMappingProp.vehicle.warrantyMileageInterval }} </td>
          <th>Warranty Time Interval</th>
          <td>{{ vehicleMappingProp.vehicle.warrantyTimeInterval }}</td>
        </tr>
        <tr>
          <th>Warranty</th>
          <td colspan="3">{{ vehicleMappingProp.vehicle.warranty }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>

import { dateHelper } from "@/helpers";

export default {
  name: "vehicle-details",
  props: {
    vehicleMappingProp: Object,
    hideImageProp: Boolean
  },
  data: () => ({
    dateHelper
  })
}

</script>

<style scoped>
.vehicle-details-table .vehicle-details-table-heading {
  font-size: 16px;
  background-color: #343a40;
  color: #fff !important;
  text-align: center !important;
}

.vehicle-details-table tbody tr:nth-of-type(2n+1) {
  background-color: rgba(0, 0, 0, 0.05);
}

.vehicle-details-table tbody th,
.vehicle-details-table tbody td {
  width: 25%;
  text-align: left;
  font-size: 14px !important;
  border: 1px solid #dee2e6;
}
</style>