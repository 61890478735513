export const contactSpeedDial = {
  namespaced: true,
  state: {
    show: false
  },
  actions: {
    setShow({ commit }, show) {
      commit("setShow", show);
    },
    toggle({commit}) {
      commit("toggle");
    },
    clear({ commit }) {
      commit("clear");
    }
  },
  mutations: {
    setShow(state, show) {
      state.show = show;
    },
    toggle(state) {
      state.show = !state.show;
    },
    clear(state) {
      state.show = false;
    }
  },
  getters: {
    getShow: state => {
      return state.show;
    }
  }
};
