import { jsPDF } from "jspdf";
import { robotoRegular, robotoBold, robotoItalic } from "@/assets/fonts";

var callAddFont = function () {
    this.addFileToVFS('Roboto-Regular-normal.ttf', robotoRegular);
    this.addFont('Roboto-Regular-normal.ttf', 'Roboto-Regular', 'normal');

    this.addFileToVFS('Roboto-Bold-normal.ttf', robotoBold);
    this.addFont('Roboto-Bold-normal.ttf', 'Roboto-Bold', 'normal');

    this.addFileToVFS('Roboto-Italic-normal.ttf', robotoItalic);
    this.addFont('Roboto-Italic-normal.ttf', 'Roboto-Italic', 'normal');
};

const initializeJsPdf = () => {

    // import font;
    jsPDF.API.events.push(['addFonts', callAddFont])

    return new jsPDF({
        format: "a4",
        putOnlyUsedFonts: true,
        compress: true
    });
};

const initializeDocument = () => {
    const document = initializeJsPdf();

    return document;
};

export const pdfService = {
    initializeDocument
}