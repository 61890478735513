export default class WorkshopReview {
    constructor(
        id,
        workshopId,
        rating,
        review
    ) {
        this.id = id;
        this.workshopId = workshopId;
        this.rating = rating;
        this.review = review;
    }
}