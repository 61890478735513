<template>
  <v-stepper-content step="1">
    <ValidationObserver ref="step1formValidationObserver">
      <v-form v-model="step1Valid" lazy-validation ref="step1form">
        <v-card flat>
          <div class="pt-1 mt-1"></div>
          <v-row>
            <v-col cols="12" md="5">
              <v-text-field
                id="createQuote-brand"
                v-model="quoteRequest.vehicle.brand"
                label="Vehicle brand"
                outlined
                rounded
                prepend-inner-icon="mdi-car"
                readonly
              />
              <v-text-field
                id="createQuote-range"
                v-model="quoteRequest.vehicle.range"
                label="Vehicle range"
                outlined
                rounded
                prepend-inner-icon="mdi-car-back"
                readonly
              />
              <v-text-field
                id="createQuote-model"
                v-model="quoteRequest.vehicle.model"
                label="Vehicle model"
                outlined
                rounded
                prepend-inner-icon="mdi-car-back"
                readonly
              />
            </v-col>
            <v-col v-if="this.$vuetify.breakpoint.mdAndUp">
              <v-divider role="presentation" vertical></v-divider>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                id="createQuote-vehicleYear"
                v-model="quoteRequest.vehicle.year"
                label="Vehicle year"
                outlined
                rounded
                prepend-inner-icon="mdi-calendar"
                readonly
              />
              <v-text-field
                id="createQuote-vehicleTransmission"
                v-model="quoteRequest.vehicle.transmissionType"
                label="Vehicle transmission"
                outlined
                rounded
                prepend-inner-icon="mdi-car-shift-pattern"
                readonly
              />
              <ValidationProvider
                v-slot="{ errors }"
                name="Vehicle mileage"
                :rules="`required|numeric|min_value:${
                  quoteRequest.existingVehicleMileage
                    ? quoteRequest.existingVehicleMileage
                    : 0
                }|max_value:1000000`"
              >
                <v-text-field
                  id="createQuote-vehicleMileage"
                  v-model="quoteRequest.vehicleMileage"
                  :error-messages="errors"
                  label="Vehicle mileage"
                  :hint="
                    quoteRequest.existingVehicleMileage
                      ? `Current mileage: ${quoteRequest.existingVehicleMileage.toLocaleString('en-ZA')} KM`
                      : ``
                  "
                  outlined
                  rounded
                  prepend-inner-icon="mdi-sign-direction"
                  suffix="KM"
                  maxLength="6"
                  type="number"
                  persistent-hint
                  v-on:keyup.enter="$event.target.blur()"
                />
                <v-btn 
                  small 
                  rounded 
                  class="float-right" 
                  outlined 
                  color="primary"
                  v-if="quoteRequest && quoteRequest.existingVehicleMileage && (quoteRequest.vehicleMileage != quoteRequest.existingVehicleMileage)"
                  @click="quoteRequest.vehicleMileage = quoteRequest.existingVehicleMileage"
                >
                  Use {{ `${quoteRequest.existingVehicleMileage.toLocaleString('en-ZA')} KM` }}
              </v-btn>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                id="createQuote-step1Reset"
                rounded
                color="secondary"
                class="smc-text-button smc-medium-button mx-1 my-1"
                @click="
                  resetForm({
                    resetQuoteRequest: true,
                    skipConfirmation: false,
                  })
                "
                :disabled="stepLoading"
              >
                <span v-if="!stepLoading"> Clear </span>
                <v-progress-circular
                  v-else
                  :size="25"
                  indeterminate
                  color="secondary"
                ></v-progress-circular>
              </v-btn>
              <v-btn
                id="createQuote-step1Next"
                rounded
                color="primary"
                class="smc-text-button smc-medium-button mx-1 my-1"
                @click="stepForward(2)"
                :disabled="!step1Valid || stepLoading"
              >
                <span v-if="!stepLoading"> Next </span>
                <v-progress-circular
                  v-else
                  :size="25"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-stepper-content>
</template>

<script>
import quoteRequestCreate from "@/mixins/quote-request-create";
import validate from "@/mixins/validator";

export default {
  name: "create-step-1",
  mixins: [quoteRequestCreate, validate],
};
</script>