<template>
  <v-footer dark absolute color="secondary" class="pt-6">
    <v-row>
      <v-col xl="3" lg="4" md="4" sm="12" cols="12" class="pb-0">
        <v-img
          src="@/assets/images/smc-logo-white.svg"
          lazy-src="@/assets/images/smc-logo-white.svg"
          class="mx-auto"
          width="300"
          height="55"
        />
        <br /><br />
        <v-row>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn
              text
              tile
              small
              class="text-none footer-text"
              active-class="smc-text-btn-hide-active"
              to="/terms"
            >
              Terms & conditions
            </v-btn>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col>
            <v-btn
              text
              tile
              small
              class="text-none footer-text"
              active-class="smc-text-btn-hide-active"
              to="/terms"
            >
              Privacy Policy
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
      <v-col xl="2" lg="2" md="2" sm="4" cols="4" class="pb-0">
        <h5 class="text-left pl-2">Site Map</h5>
        <v-list
          tile
          dense
          flat
          max-width="100"
          color="transparent"
          class="text-left footer-dense-list"
        >
          <v-list-item to="/" class="pl-2">
            <v-list-item-content class="py-0">
              <v-list-item-title class="font-weight-regular">
                Quote
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/" class="pl-2">
            <v-list-item-content class="py-0">
              <v-list-item-title class="font-weight-regular">
                Home
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/about" class="pl-2">
            <v-list-item-content class="py-0">
              <v-list-item-title class="font-weight-regular">
                About
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/contact" class="pl-2">
            <v-list-item-content class="py-0">
              <v-list-item-title class="font-weight-regular">
                Contact
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/account/login" class="pl-2">
            <v-list-item-content class="py-0">
              <v-list-item-title class="font-weight-regular">
                Login
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col xl="2" lg="2" md="2" sm="4" cols="4" class="pb-0">
        <h5 class="text-left">Contact</h5>
        <p class="text-left font-weight-regular footer-text pt-2 mb-0">
          T: {{ contactNumber }}
        </p>
        <p class="text-left font-weight-regular footer-text">
          A: East Block,<br />
          Centennial Place,<br />
          Century Blvd,<br />
          Century City,<br />
          Cape Town
        </p>
        <p></p>
      </v-col>
      <v-col xl="2" lg="2" md="2" sm="4" cols="4" class="pb-0">
        <h5 class="text-left">Socials</h5>
        <!--
        <v-btn
          text
          tile
          :href="facebookLink"
          target="blank"
          class="text-none font-weight-regular footer-text float-left ml-0 pl-0"
        >
          <v-icon class="mr-2">mdi-facebook</v-icon>
          Facebook
        </v-btn>
        <div class="mx-12 py-5"></div>
        -->
        <v-btn
          text
          tile
          :href="instagramLink"
          target="blank"
          class="text-none font-weight-regular footer-text float-left ml-0 pl-0"
        >
          <v-icon class="mr-2">mdi-instagram</v-icon>
          Instagram
        </v-btn>
        <div class="mx-12 py-5"></div>
        <v-btn
          text
          tile
          :href="linkedinLink"
          target="blank"
          class="text-none font-weight-regular footer-text float-left ml-0 pl-0"
        >
          <v-icon class="mr-2">mdi-linkedin</v-icon>
          LinkedIn
        </v-btn>
        <div class="mx-12 py-5"></div>
        <v-btn
          text
          tile
          :href="twitterLink"
          target="blank"
          class="text-none font-weight-regular footer-text float-left ml-0 pl-0"
        >
          <v-icon class="mr-2">mdi-twitter</v-icon>
          Twitter
        </v-btn>
        <div class="mx-12 py-5"></div>
      </v-col>
      <v-col xl="2" lg="2" md="2" sm="12" cols="12" class="pb-0">
        <v-row>
          <v-col cols="6" md="12">
            <h5 class="text-left pl-2">New to SMC</h5>
            <v-list
              dense
              flat
              tile
              max-width="100"
              color="transparent"
              class="text-left footer-dense-list"
            >
             <v-list-item to="/account/signup" class="pl-2">
                <v-list-item-content class="py-0">
                  <v-list-item-title class="font-weight-regular">
                    Register here
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/account/invite" class="pl-2">
                <v-list-item-content class="py-0">
                  <v-list-item-title class="font-weight-regular">
                    Invite
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6" md="12">
            <h5 class="text-left pl-2">Workshops</h5>
            <v-list
              dense
              flat
              tile
              max-width="200"
              color="transparent"
              class="text-left footer-dense-list"
            >
              <v-list-item to="/workshops" class="pl-2">
                <v-list-item-content class="py-0">
                  <v-list-item-title class="font-weight-regular">
                    View workshops
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-sheet dark class="mb-n4 ml-n4 mr-n4 mt-4 pa-1 caption smc-copyright-bar">
      &copy; Service My Car - {{ new Date().getFullYear() }} <span>(v{{appVersion}})</span>
    </v-sheet>
  </v-footer>
</template>

<script>
export default {
  name: "site-footer",
  data: () => {
    return {
      facebookLink: process.env.VUE_APP_SOCIAL_FACEBOOK,
      twitterLink: process.env.VUE_APP_SOCIAL_TWITTER,
      instagramLink: process.env.VUE_APP_SOCIAL_INSTAGRAM,
      linkedinLink: process.env.VUE_APP_SOCIAL_LINKEDIN,
      contactNumber: process.env.VUE_APP_CONTACT_NUMBER,
      appVersion: process.env.VUE_APP_VERSION
    };
  },
};
</script>

<style scoped>
h5 {
  font-size: 0.85em;
}

.v-footer {
  position: relative;
}

.footer-dense-list.v-list--dense .v-list-item {
  min-height: 22px;
}

.footer-dense-list.v-list--dense .v-list-item .v-list-item__title,
.footer-text {
  font-size: 0.8125rem;
}

.smc-copyright-bar {
  border-radius: 0;
  width: 100vw;
}
</style>
