<template>
  <video-background
    ref="videoBackgroundPlayer"
    :src="require('@/assets/videos/cars-backgournd-large.mp4')"
    :poster="require('@/assets/images/cars-background-poster.jpg')"
    :sources="[
      {
        src: require('@/assets/videos/cars-backgournd-small.mp4'),
        res: 900,
        autoplay: true,
        poster: require('@/assets/images/cars-background-poster.jpg'),
      },
      /*{
        src: require('@/assets/videos/cars-backgournd-small.mp4'),
        res: 601,
        autoplay: true,
        poster: require('@/assets/images/cars-background-poster.jpg'),
      },*/
      {
        res: 600,
        poster: require('@/assets/images/cars-mobile-background-poster.jpg'),
      },
    ]"
    style="
      max-height: 700px;
      height: 100vh;
      position: absolute;
      left: 0;
      top: 0;
    "
  >
  </video-background>
</template>

<script>
export default {
  name: "background-video",
  methods: {
    pauseVideo() {
      this.$refs.videoBackgroundPlayer.player.pause();
    },
    playVideo() {
      this.$refs.videoBackgroundPlayer.player.play();
    },
  },
};
</script>

<style scoped>
.vue-responsive-videobg {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}
</style>